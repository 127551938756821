<template>
  <div class="container-fluid mt-4">
    <div id="profile" class="card card-body mt-4">
      <div class="row justify-content-center align-items-center">

        <div class="col-sm-auto col-4">
          <soft-avatar
            :img="pic"
            alt="profile"
            size="xl"
            shadow="sm"
            border-radius="lg"
          />
        </div>

        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">{{ profile?.name }}</h5>
            <p class="mb-0 font-weight-bold text-sm">{{ profile?.email }}</p>
          </div>
        </div>

      </div>

      <div class="row mt-5">
        <hr>
        <h5 class="text-center">Por favor verifica tu correo electrónico</h5>
        <p class="text-center">Si no puedes visualizar el correo de verificación, por favor checa en la bandeja de spam.</p>
        <button @click="startCountdown" :disabled="countingDown" type="button" class="btn btn-success mx-auto" style="width: auto;">
          <span v-if="countingDown"> Enviar en {{ formatTime(countdown) }} </span>
          <span v-else> ¿Enviar nuevamente? </span>
        </button>
        <span class="mt-5">
          ¿Ya verificaste tu cuenta? <button class="btn" type="button" v-on:click="reloadPage">Recarga la página</button>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import authService from "../services/auth.service";

export default {
  name: "VerifyEmail",
  components: {
    SoftAvatar,
  },
  mixins: [formMixin],
  data() {
    return {
      img,
      countingDown: false,
      countdown: 90,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    pic() {
      return this.profile?.profile_image ?? require("/src/assets/img/placeholder.jpg");
    },
  },
  async beforeCreate() {

    // await this.$store.dispatch("profile/getProfile")

    if (this.$store.getters["profile/isVerified"]) {

      return this.$router.go(-1)
    }
  },
  methods: {
    async reloadPage() {
      await this.$store.dispatch("profile/getProfile")
      localStorage.setItem('isVerified', this.profile['email_verified_at']);
      this.$router.push({name: 'Landing'})
    },
    startCountdown() {
      authService.resendConfirmation();
      this.countingDown = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.countingDown = false;
      this.countdown = 90;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}
</style>
