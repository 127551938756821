import { useFetch } from "../Http";
const backend = (shipper = '') => `${process.env.VUE_APP_API_BASE_URL}/admin/shippers${shipper}`;

const getShippers = async () => {

    const {data} = await useFetch(backend());

    return data.value
}

const updateShipper = async (id, data) => {

    const {error} = await useFetch(backend(`/${id}`), 'put', data);

    return error.value
}



export { getShippers, updateShipper }