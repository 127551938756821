
export const dashboard = {
    namespaced: true,
    state: () => ({
        search: {
            quote: null,
            shipper: null,
            recipient: null,
            locationsInfo: {
                shipper: null,
                recipient: null
            },
            pkg: null
        }
    }),

    mutations: {
        setQuote(state, quote) {
            state.search.quote = quote;
        },

        setPackage(state, pkg) {
            state.search.pkg = pkg;
        },

        setLocations(state, locations) {
            state.search.locationsInfo = locations;
        }
    },

    actions: {},

    getters: {
        quote(state) {
            return state.search.quote?.quote_id;
        },

        search(state) {
            return state.search.quote?.search_id;
        }
    }
}