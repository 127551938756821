<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="py-2 fa fa-cog"> </i>
    </a>
    <div class="shadow-lg card blur">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Configuración de {{ appName }}</h5>
          <p></p>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <div>
          <h6 class="mb-0">Paqueterías</h6>
          <p class="text-sm">Configure los descuentos</p>

          <table class="text-xs">
            <thead>
              <tr class="text-center w-100">
                <th>Nombre</th>
                <th>% cuenta</th>
                <th>% clientes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="shipper in shippers" :key="shipper.shipping_id">
                <td style="padding: 2px !important;">{{ shipper.name }}</td>
                <td style="padding: 2px !important;">
                  <input
                    class="form-control form-control-sm w-75 mx-auto"
                    type="number"
                    :disabled="isLoading"
                    v-model="shipper.account_discount">
                </td>
                <td style="padding: 2px !important;">
                  <input
                    class="form-control form-control-sm w-75 mx-auto"
                    type="number"
                    :disabled="isLoading"
                    v-model="shipper.client_discount">
                </td>
                <td style="padding: 2px !important;">
                  <button
                    class="btn bg-gradient-dark mb-0"
                    :disabled="isLoading"
                    @click="updateShipper(shipper.shipping_id)">
                    <i class="fa fa-floppy-o fa-lg" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Option example
        <div class="mt-3">
          <h6 class="mb-0">Option</h6>
          <p class="text-sm">Option description</p>
        </div>
        -->

      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import showSwal from "/src/mixins/showSwal.js";
import { getShippers, updateShipper } from "@/services/Admin/Shippers";

export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      isLoading: true,
      shippers: []
    };
  },
  methods: {
    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");

      if (!transparent) {
        return;
      }

      let white = document.querySelector("#btn-white");

      if (window.innerWidth < 1200) {
        transparent.classList.add("disabled");
        white.classList.add("disabled");
      } else {
        transparent.classList.remove("disabled");
        white.classList.remove("disabled");
      }
    },

    async getShippers() {
      const {shippers} = await getShippers()
      this.shippers = shippers;
      this.isLoading = false;
    },

    updateShipper (id) {
      this.isLoading = true;
      const value = this.shippers.filter((s) => s.shipping_id == id)[0]
      updateShipper(id, value).then(async (err) => {

        (err)
          ? Swal.fire({
            title: "Error",
            text: "No se ha podido actualizar el valor, intente de nuevo.",
            icon: "error"
          })

          : showSwal.methods.showSwal({
            type: "success",
            message: `${value.name} actualizado !`,
            timer: 3500
          });

          await this.getShippers();
      })

    }

  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },

  async mounted() {
    await this.getShippers();
  },

  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    // Deactivate sidenav type buttons on resize and small screens
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
};
</script>

<style scoped>

button {
  padding: 10px 15px 10px 15px !important;
}

</style>
