export default function authHeader() {
  let user = JSON.parse(localStorage.getItem("userF"));

  if (user) {
    return {
      Authorization: "Bearer " + user,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      "Accept": "application/json",
    };
  }
}
