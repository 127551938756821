import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/auth/';
const APP_URL = process.env.VUE_APP_BASE_URL;

export default {
  async getProfile() {
    try {
      const response = await axios.get(API_URL + "me", {
        headers: authHeader(),
      });

      return await response.data
    } catch (error) {

      if (error.response.status == 401) {
        localStorage.clear();
        window.location.href = APP_URL + '#/login';
      }

    }
  },

  async updateProfile(profile) {
    profile.type = "profile";
    delete profile.links;
    const response = await axios.patch(
      API_URL + "me",
      profile,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  },
};
