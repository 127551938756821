<template>

  <div class="container-fluid searcher mb-5 p-3">
    <Form @submit="submit" :validation-schema="schema">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row mb-2">
            <h4 class="text-white">¿Que enviarás hoy?</h4>
          </div>
          <div class="row g-1">
            <div class="col-md-4">
              <label for="in_tipo" class="form-label text-white">Tipo</label>
              <select
                name="type"
                class="form-select border-0"
                v-model="payload.package.type"
              >
                <option selected value="1">Caja</option>
                <option value="0">Sobre</option>
              </select>
            </div>
            <div class="col-md-2 in_for_box">
              <label for="in_peso" class="form-label text-white"
                >Peso <small>(kg)</small></label
              >
              <Field
                autocomplete="off"
                name="mass"
                type="number"
                class="form-control border-0"
                placeholder="kg"
                v-model="payload.package.mass"
              />
              <ErrorMessage
                class="badge bg-white text-wrap text-danger text-sm-center"
                name="mass"
              />
            </div>
            <div class="col-md-2 in_for_box">
              <label for="in_largo" class="form-label text-white"
                >Largo <small>(cm)</small></label
              >
              <Field
                autocomplete="off"
                name="high"
                type="number"
                class="form-control border-0"
                placeholder="cm"
                v-model="payload.package.long"
              />
              <ErrorMessage
                class="badge bg-white text-wrap text-danger text-sm-center"
                name="high"
              />
            </div>
            <div class="col-md-2 in_for_box">
              <label for="in_alto" class="form-label text-white"
                >Alto <small>(cm)</small></label
              >
              <Field
                autocomplete="off"
                name="width"
                type="number"
                class="form-control border-0"
                placeholder="cm"
                v-model="payload.package.high"
              />
              <ErrorMessage
                class="badge bg-white text-wrap text-danger text-sm-center"
                name="width"
              />
            </div>
            <div class="col-md-2 in_for_box">
              <label for="in_ancho" class="form-label text-white"
                >Ancho <small>(cm)</small></label
              >
              <Field
                autocomplete="off"
                name="long"
                type="number"
                class="form-control border-0"
                placeholder="cm"
                v-model="payload.package.width"
              />
              <ErrorMessage
                class="badge bg-white text-wrap text-danger text-sm-center"
                name="long"
              />
            </div>

            <strong class="text-xs text-white mt-4 text-end">
              <span v-if="payload.package.mass">Peso masa: {{ payload.package.mass }} kg</span>
              <template v-if="volumetricWeight !== '-'">
                 <span
                  class="cursor-pointer"
                  data-placement="top"
                  data-bs-toggle="tooltip"
                  title="Es el peso del paquete según sus dimensiones entre 5.000, Por ejemplo (30, 20, 70) / 5.000 = 9 Kg"
                >
                  | Peso volumétrico: {{ volumetricWeight }} kg  |
                </span>
                <span
                  class="bg-white text-dark rounded p-1 cursor-pointer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Es el peso mayor entre peso masa y volumétrico."
                >
                  Peso a cotizar: {{ toWeightQuote }} kg
                </span>
              </template>
            </strong>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="row mb-2">
            <h5 class="text-white second_text">¿A dónde se va 🛫?</h5>
          </div>
          <div class="row g-1 locationInput">
            <div class="col-md-6 col-12">
              <label for="in_origen" class="form-label text-white"
                >Origen <small>(CP)</small></label
              >
              <v-select
                class="bg-white rounded"
                v-model="payload.locations.shipper"
                :options="shipperOptions"
                @search="fetchShipperOptions"
                index="id"
                label="location"
                placeholder="Origen"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No hay resultados para el CP: <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Ingrese el CP a 5 dígitos </em>
                </template>
                <template #search="{ attributes, events }">
                  <input
                    maxlength="5"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <span
                role="alert"
                id="shipperMessage"
                class="badge bg-white text-wrap text-danger text-sm-center d-none"
                >El origen es requerido</span
              >
              <small class="form-label text-white">{{ shipperOptions.length ? ' Mostrando ubicaciones recientes*' : '' }}</small>
            </div>
            <div class="col-md-6 col-12">
              <label for="in_destino" class="form-label text-white"
                >Destino <small>(CP)</small></label
              >
              <v-select
                class="bg-white rounded"
                v-model="payload.locations.recipient"
                :options="recipientOptions"
                @search="fetchRecipientOptions"
                index="id"
                label="location"
                placeholder="Destino"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No hay resultados para el CP: <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Ingrese el CP a 5 dígitos </em>
                </template>
                <template #search="{ attributes, events }">
                  <input
                    maxlength="5"
                    type="number"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <span
                role="alert"
                id="recipientMessage"
                class="badge bg-white text-wrap text-danger text-sm-center d-none"
                >El destino es requerido</span
              >
              <small class="form-label text-white">{{ recipientOptions.length ? ' Mostrando ubicaciones recientes*' : '' }}</small>
            </div>

            <div class="form-check d-flex justify-content-end">
              <input class="form-check-input" type="checkbox" v-model="payload.locations.willBeStored" id="flexCheckChecked" checked>
              <label class="form-check-label text-white" for="flexCheckChecked">
                <strong>¿Guardar ubicación?</strong>
              </label>
            </div>


            <button
              v-if="!isDisabled"
              id="btn_cotizar"
              type="submit"
              class="btn bg-gradient-dark w-100"
              :disabled="isLoading"
            >
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm mx-1"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <span>Cotizar</span>
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>

  <!-- No quotes message -->
  <div v-if="quotesNotFound" class="row justify-content-center">
    <h5 class="">¡Ups! Parece que necesitamos más detalles...</h5>
    <h6 class="mb-5">No hemos encontrado cotizaciones para los datos proporcionados. Asegúrate de ingresar las dimensiones, peso y códigos postales correctos.</h6>

    <div class="row d-flex justify-content-center mb-3">
      <div class="col-6">
        <div class="card-body border-radius-lg bg-gradient-dark p-3">
          <h6 class="mb-3 text-white">¿Necesitas ayuda?</h6>
          <p class="text-white text-sm mb-4">
            Estamos aquí para ayudarte a obtener la mejor opción de envío.
            Contacta a nuestro equipo de soporte para asistencia personalizada.
          </p>
          <button @click="supportEmptyQuotes" role="button" class="btn mb-0 bg-gradient-light btn-md null null mb-0">Soporte</button>
        </div>
      </div>
    </div>

  </div>

  <!-- Quotes list -->
  <div v-if="quotesList.length" id="quotes_list" class="row justify-content-center">
    <div
      class="card col-10 bg-base-color border rounded box-shadow table-responsive mb-3"
    >
      <div class="px-5 mt-5">
        <h6 class="m-0">Selecciona la mejor opción para ti.</h6>
        <p class="m-0 text-sm font-weight-normal">Compara servicios, tiempos de entrega y costos, todo con descuentos exclusivos que solo EnviOy puede ofrecer.</p>
      </div>
      <table style="min-width: 900px" class="mt-5 mb-5">
        <thead>
          <th style="text-align: center; width: 20%;">Proveedor</th>
          <th style="width: 20%;">Tipo de servicio</th>
          <th style="width: 15%;">Tiempo en viaje</th>
          <th style="text-align: center; width: 20%;">Precio</th>
          <th style="width: 20%;"></th>
        </thead>
        <tbody>
          <tr v-for="quote in quotesList" :key="quote.id">
            <td>
              <div style="text-align: center">
                <img
                  :src="photo(quote.shipping_provider.photo)"
                  class="img_brand"
                  :alt="quote.shipping_provider.name"
                />
              </div>
            </td>
            <td><p class="text-truncate" v-html="quote.shipping_type"></p></td>
            <td><p v-html="quote.delivery_date"></p></td>
            <td>
              <div class="d-flex mt-3">
                <div class="position-relative">
                  <span class="fs-4 d-block">{{ quote.price }}</span>
                    <span class="position-absolute top-0 start-100 translate-middle badge text-dark" style="margin-top: -0.1rem; margin-left: 0.5rem;">
                      Público
                    <strong class="bg-danger rounded text-white p-1" style="border-radius: 0.5rem; text-decoration: line-through;">
                      {{ quote.public_price }}
                    </strong>
                    <span class="visually-hidden">previous price</span>
                  </span>
                </div>
              </div>
            </td>
            <td>
              <router-link @click="select(quote)" to="#formWizard" @click.prevent="$scrollToSection('formWizard')" class="btn btn-primary text-white">
                Elegir
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script setup>
import { computed, onBeforeMount, reactive, ref, watch, defineProps } from "vue";
import { useStore } from "vuex";
/* eslint-disable no-unused-vars */
import { Form, Field, ErrorMessage } from "vee-validate";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as yup from "yup";
/* eslint-disable no-unused-vars */
import services from "@/services/Search/index.js";
import showSwal from "@/mixins/showSwal.js";

const phone = process.env.VUE_APP_SUPPORT_WHATS;
const appName = process.env.VUE_APP_NAME;
/* End imports */

const props = defineProps({
  isDisabled: Boolean
});

const store = useStore()

const requiredMessage = "El campo es requerido.";
const positiveMessage = "El campo debe ser un número entero positivo.";
const integerMessage = "El campo debe ser un número entero.";

const validator = yup.number().transform((value) => (isNaN(value) ? undefined : value));

const schema = yup.object({
  mass: validator
    .required(requiredMessage)
    .positive(positiveMessage)
    .integer(integerMessage),
  high: validator
    .required(requiredMessage)
    .positive(positiveMessage)
    .integer(integerMessage),
  width: validator
    .required(requiredMessage)
    .positive(positiveMessage)
    .integer(integerMessage),
  long: validator
    .required(requiredMessage)
    .positive(positiveMessage)
    .integer(integerMessage),
});

/* State */
const payload = reactive({
  package: {
    type: 1,
    mass: null,
    high: null,
    width: null,
    long: null,
  },
  locations: {
    shipper: null,
    recipient: null,
    willBeStored: true
  },
});

const isLoading = ref(false);
const quotesNotFound = ref(false);
const shipperOptions = ref([]);
const recipientOptions = ref([]);

const quotesList = ref([]);

onBeforeMount(async () => {
  const {data: {locations:{ shipper, recipient }}} = await services.getMostWanted()

  shipperOptions.value = shipper

  recipientOptions.value = recipient

})

/* Methods */
watch(
  () => payload.locations.shipper,
  (newVal, old) => {
    const input = document.querySelectorAll(".vs__search")[0];

    if (newVal) {
      return input.style.setProperty("display", "none", "important");
    }
    if (old) {
      return input.style.setProperty("display", "block", "important");
    }
  }
);

watch(
  () => payload.locations.recipient,
  (newVal, old) => {
    const input = document.querySelectorAll(".vs__search")[1];

    if (newVal) {
      return input.style.setProperty("display", "none", "important");
    }
    if (old) {
      return input.style.setProperty("display", "block", "important");
    }
  }
);

const volumetricWeight = computed(() => {

  const high  = payload.package.high ?? 0;
  const width = payload.package.width ?? 0;
  const long  = payload.package.long ?? 0;

  const vol = (high * width * long);

  if (vol <= 0) {
    return '-'
  }

  return Math.ceil(vol / 5000)
})

const toWeightQuote = computed(() => {
  return Math.max(payload.package.mass, volumetricWeight.value)
});

const photo = (url) => `${process.env.VUE_APP_LANDING_URL}${url}`;
const fetchShipperOptions = async (search, loading) => await fetchOptions(search, loading, shipperOptions);
const fetchRecipientOptions = async (search, loading) => await fetchOptions(search, loading, recipientOptions);
const fetchOptions = async (search, loading, ref) => {
  if (search.length === 5) {
    loading(true);
    const { data } = await services.getLocationsByCp(search);
    ref.value = data.locations;
    loading(false);
  }
};

const submit = async () => {
  if (!payload.locations.shipper) {
    return document.getElementById("shipperMessage").classList.remove("d-none");
  }

  if (!payload.locations.recipient) {
    return document.getElementById("recipientMessage").classList.remove("d-none");
  }

  store.commit('dashboard/setQuote', null)
  store.commit('dashboard/setPackage', {...payload.package})

  document.getElementById("shipperMessage").classList.add("d-none");
  document.getElementById("recipientMessage").classList.add("d-none");

  isLoading.value = true;
  quotesNotFound.value = false;

  const { data } = await services.getQuotes(payload, toWeightQuote.value);

  if (!data.quotes.length) {
    showSwal.methods.showSwal({
      type: "success",
      width: "auto",
      message: "Sin cotizaciones.",
    });
    quotesNotFound.value = true;
  }

  quotesList.value = data.quotes;

  store.commit('dashboard/setLocations', {
    shipper: data.shipper,
    recipient: data.recipient
  })

  isLoading.value = false;
};

const select = (quote) => {
  store.commit('dashboard/setQuote', quote)
};

const supportEmptyQuotes = () => {

  const pkg = payload.package;
  const from = payload.locations.shipper.location;
  const to = payload.locations.recipient.location;
  const user = store.state.profile.profile.email;

  let message = `Hola, soy ${user} necesito ayuda en ${appName}. Realicé una cotización de ${from} a ${to} y no obtuve resultados. Las dimensiones de mi paquete son: ${JSON.stringify(pkg)}, podría ayudarme ?`;
  message = encodeURIComponent(message)
  const url = `https://wa.me/52${phone}?text=${message}`
  window.open(url, "_blank");
}

</script>

<style scoped>
.img_brand {
  max-height: 50px;
  max-width: 150px;
}
</style>
