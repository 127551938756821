<script setup>
/* eslint-disable no-unused-vars */
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Form } from "vee-validate";
import SoftField from "@/components/SoftField.vue";
import SoftBadge from '@/components/SoftBadge.vue';
import { useCreateUserSchema } from "../../services/Http/Validations/Admin";
import showSwal from "/src/mixins/showSwal.js";
import { createCollaborator, updateCollaborator, deleteCollaborator, reactivateCollaborator } from "../../services/Admin";

const {
  params: { id },
} = useRoute();

/* eslint-disable no-undef */
const props = defineProps({
  editUser: {
    type: Object,
    default: null
  }
})

// console.log(props);

const router = useRouter()
const schema = useCreateUserSchema()
const isLoading = ref(false)
const user = ref(props.editUser ?? {});
const isEditing = ref(Object.keys(props.editUser ?? {}).length > 0)
const isDisabled = ref(Object.keys(props.editUser ?? {}).length > 0 && props.editUser.pivot.deleted_at !== null)

const formatDate = (date) => {

  date = new Date(date);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

}

const store = async () => {

  if (isEditing.value) {
    return update()
  }

  isLoading.value = true
  const { error } = await createCollaborator(id, user.value);

  if (error.value != undefined) {

    return showSwal.methods.showSwal({
      type: "error",
      message: error.value.message,
      width: "auto",
      timer: 5000
    });
  }

  showSwal.methods.showSwal({
    type: "success",
    message: 'Éxito',
    width: "auto"
  });

  router.go()

}

const update = async () => {
  isLoading.value = true

  const payload = {
    name: user.value.name,
    email: user.value.email,
  };

  const { value: error } = await updateCollaborator(id, props.editUser.id, payload);

  if (error) {

    return showSwal.methods.showSwal({
      type: "error",
      message: error.message,
      width: "auto",
      timer: 5000
    });
  }

  showSwal.methods.showSwal({
    type: "success",
    message: 'Éxito',
    width: "auto"
  });

  router.go()
}

const delUser = async () => {
  isLoading.value = true

  if (!confirm('¿Estás seguro de inhabilitar a este colaborador? Su historial de consumo continuará reflejándose en la factura mensual, incluso después de la eliminación.')) {
    isLoading.value = false;
    return;
  }

  const { value: error } = await deleteCollaborator(id, props.editUser.id);

  if (error) {

    return showSwal.methods.showSwal({
      type: "error",
      message: error.message,
      width: "auto",
      timer: 5000
    });
  }

  showSwal.methods.showSwal({
    type: "success",
    message: 'Éxito',
    width: "auto"
  });

  router.go()
}

const recoverUser = async () => {

  isLoading.value = true;
  if (!confirm('¿Estás seguro de reactivar la cuenta de este colaborador? Su historial de consumo volverá a sumarse a la factura mensual.')) {
    isLoading.value = false;
    return;
  }

  const { value: error } = await reactivateCollaborator(id, props.editUser.id);

  if (error) {

    return showSwal.methods.showSwal({
      type: "error",
      message: error.message,
      width: "auto",
      timer: 5000
    });
  }

  showSwal.methods.showSwal({
    type: "success",
    message: 'Éxito',
    width: "auto"
  });

  router.go()

}

</script>

<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5> {{Object.keys(editUser ?? {}).length ? 'Editar' : 'Crear' }} usuario</h5>
      <SoftBadge
        v-if="isEditing && isDisabled"
        :color="'danger'"
        variant="gradient"
        size="sm"
      > El usuario ha sido inhabilitado el {{ editUser.pivot.deleted_at }}</SoftBadge>
    </div>
    <Form
      @submit="store"
      :validation-schema="schema"
      role="form"
      class="text-start"
    >
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">Nombre</label>
            <div class="form-group">
              <soft-field id="name" name="name" v-model="user.name" type="text"/>
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label">Correo</label>
            <div class="form-group">
              <soft-field
                id="email"
                name="email"
                v-model="user.email"
                type="email"
              />
            </div>
          </div>
        </div>
        <div class="row container text-sm" v-if="!isEditing">
          <h6>Antes de agregar un nuevo colaborador</h6>

          <p class="text-sm">Aquí hay algunas cosas a tener en cuenta:</p>
          <div class="container">
            <ul>
              <li>Una vez creado, el colaborador recibirá un correo electrónico notificándole su asignación a la empresa.</li>
              <li>Generaremos una contraseña temporal para el colaborador y se la enviaremos por correo electrónico junto con instrucciones para cambiarla rápidamente.</li>
              <li>Las consultas realizadas por este usuario se sumarán a la factura mensual de la empresa.</li>
              <li>Recuerda que puedes inhabilitar la cuenta del colaborador en cualquier momento desde la sección de gestión de colaboradores.</li>
            </ul>
          </div>
          <p class="text-sm">Por favor, asegúrate de proporcionar la información correcta antes de hacer clic en "Agregar Colaborador". ¡Gracias por hacer crecer tu equipo con nosotros!</p>
        </div>
        <div class="row container text-sm" v-else>
          <h6>Antes de editar la información del colaborador:</h6>

          <p class="text-sm">Aquí tienes algunos puntos a tener en cuenta:</p>
          <div class="container">
            <ul>
              <li>Al realizar cambios, el colaborador recibirá un correo electrónico notificándole sobre las actualizaciones en su cuenta.</li>
              <li>Las consultas realizadas por este usuario seguirán sumándose a la factura mensual de la empresa.</li>
              <li>Recuerda que puedes inhabilitar la cuenta del colaborador en cualquier momento desde la sección de gestión de colaboradores.</li>
            </ul>
          </div>
          <p class="text-sm">Asegúrate de revisar cuidadosamente los cambios antes de hacer clic en "Guardar".</p>
          <p class="text-sm">¡Gracias por mantener actualizada la información de tu equipo!</p>
        </div>
      </div>
      <div class="card-footer d-flex float-end">
        <div v-if="isEditing && isDisabled">
          <button class="btn bg-gradient-success btn-md mb-3"
            role="button"
            :disabled="isLoading"
            @click.prevent="recoverUser"
          >
            <div
              v-if="isLoading"
              class="spinner-border spinner-border-sm mx-1"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <span>Habilitar</span>
          </button>
        </div>
        <div v-else>
          <button class="btn bg-gradient-dark btn-md mb-3 mx-3"
            type="submit"
            :disabled="isLoading"
          >
            <div
              v-if="isLoading"
              class="spinner-border spinner-border-sm mx-1"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <span>{{ isEditing ? 'Editar' : 'Agregar' }}</span>
          </button>
          <button class="btn bg-gradient-danger btn-md mb-3"
            role="button"
            v-if="isEditing"
            :disabled="isLoading"
            @click.prevent="delUser"
          >
            <div
              v-if="isLoading"
              class="spinner-border spinner-border-sm mx-1"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <span>Inhabilitar</span>
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>
