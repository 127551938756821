import profileService from "../services/profile.service";

const initialState = { profile: null };

export const profile = {
  namespaced: true,
  state: initialState,
  actions: {
    async getProfile({ commit }) {
      const response = await profileService.getProfile();
      commit("SET_PROFILE", response);
    },

    async uploadPic(_, object) {
      return await profileService.uploadPic(object);
    },

    async updateProfile({ commit }, profile) {
      const response = await profileService.updateProfile(profile);
      commit("SET_PROFILE", response);
    },
  },

  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
  },

  getters: {
    profile(state) {
      return state.profile;
    },

    role(state) {
      let name = '';
      switch (state.profile.roles) {
        case 1: name = 'SuperAdmin'; break;
        case 2: name = 'Admin'; break;
        case 3: name = 'Collaborator'; break;
        case 4: name = 'User'; break;
        default: name = 'Unknown';
      }

      return name;
    },

    isSuperAdmin(state) {
      return state.profile?.roles == 1;
    },

    isAdmin(state) {
      return state.profile?.roles == 2;
    },

    isCollaborator(state) {
      return state.profile?.roles == 3;
    },

    isUser(state) {
      return state.profile?.roles == 4;
    },

    isVerified(state) {
      return state.profile?.email_verified_at != null;
    },
  },
};
