<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
/* eslint-disable no-unused-vars */
const phone = process.env.VUE_APP_SUPPORT_WHATS;
const appName = process.env.VUE_APP_NAME;

const store = useStore()
const user = computed(() => store.getters['profile/profile']);

// console.log(user.value);

const supportEmptyQuotes = () => {

  let message = `Hola, soy ${user.value.name} - ${user.value.email} necesito ayuda en ${appName}. Mi cuenta se encuentra desactivada, es de la compañía ${user.value.company.name} - ${user.value.company.company_id}`;
  message = encodeURIComponent(message)
  const url = `https://wa.me/52${phone}?text=${message}`
  window.open(url, "_blank");
}
</script>

<template>
  <div class="card bg-gradient-danger text-white">
    <div class="card-body">
      <h4 class="text-white text-center my-3">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        Cuenta Desactivada
      </h4>
      <p class="message text-center">
          Lo sentimos, tu cuenta ha sido desactivada. Por favor, ponte en contacto con tu administrador para más información.
      </p>
      <p class="text-end p-0">
        <button class="btn btn-white" @click="supportEmptyQuotes">Soporte</button>
      </p>
    </div>
  </div>
</template>