import axios from "axios";
import authHeader from "../auth-header";
import store from "@/store";
import { ref } from "vue";
import { useRouter } from "vue-router";

const interceptor = (err, router) => {
    if (err.response.status == 401) {
        return store.dispatch('auth/logout');
    }
    if (err.response.status == 403) {
        return (! router)
            ? window.location = window.location.pathname
            : router.push({name: 'Dashboard', query: {'noAdmin': ''} })
        ;
    }

    return err.response;
};

export async function useFetch(url, method = 'get', body = {}) {
    const data = ref(null)
    const error = ref(null)
    const router = useRouter();

    try {
        const config = {
            method, url, data: body, headers: authHeader()
        };

        const response = await axios(config);

        data.value = await response.data.data;
    } catch (err) {
        // console.log(err);
        error.value = err.response.data
        interceptor(err, router)
    }

    return {data, error}
}