import axios from "axios";
import authHeader from "../auth-header";
import store from "@/store";
import showSwal from "/src/mixins/showSwal.js";

const backend = (uri = '') => `${process.env.VUE_APP_API_BASE_URL}${uri}`;

const interceptor = (err) => {

    const errMessage = err?.response?.data?.message ?? err.message;

    showSwal.methods.showSwal({
        type: "error",
        width: "auto",
        message: errMessage ?? 'Ha ocurrido un error.',
        timer: 5000
    });

    if (err.response.status == 401) {
        return store.dispatch('auth/logout');
    }
};

export default {
    async getShipments(page) {
        try {
            const response = await axios.get(
                backend(`/dashboard/shipments${page}`),
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    downloadDocument(url) {
        axios.get(url, {
            headers: {
                'Content-Type': 'application/pdf',
                ...authHeader()
              },
              responseType: 'blob', // Configura la respuesta como un objeto Blob (para archivos binarios)
            }
        ).then(response => {

            const url = response.config.url;
            const urlParts = new URL(url);
            const pathParts = urlParts.pathname.split('/');
            const lastPathSegment = pathParts[pathParts.length - 1];

            const filename = 'enviOy_guia_de_envio_' + lastPathSegment || 'enviOy_guia_de_envio.pdf';

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          })
          .catch(error => {
            console.error('Error al descargar el PDF:', error);
          });
    },

    async print(src) {
        const response = await axios.get(src,
            {
              headers: authHeader(),
              responseType: 'blob'
            }
          );

          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

          const newWindow = window.open(url, '_blank');
          if (!newWindow) {
            throw new Error('No se pudo abrir una nueva ventana.');
          }

          setTimeout(() => {
            newWindow.print();
          }, 1000);
    },

    async getCardsStats() {
        try {
            const response = await axios.get(
                backend(`/dashboard/shipments/stats`),
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    async getUsers(company) {
        try {
            const response = await axios.get(
                backend(`/b2b/companies/${company}/collaborators`),
                {headers: authHeader()}
            );

            const {data} = await response.data;

            return data.collaborators ?? [];
        } catch (error) {
            interceptor(error)
        }
    },

    async companies() {
        try {
            const response = await axios.get(
                backend(`/b2b/companies`),
                {headers: authHeader()}
            );

            const {data} = await response.data;

            return data;
        } catch (error) {
            interceptor(error)
        }
    },

    async getShipmentStatus(shipment) {
        try {
            const response = await axios.get(
                backend(`/dashboard/shipments/${shipment}/status`),
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    export(page, parser) {
        const created_at = (new Date().toLocaleDateString('en-CA')).replaceAll('-', '_');
        const filename = `EnviOy_envios_del_${page.from}_al_${page.to}_${created_at}.csv`
        page = parser(page)
        return axios.get(
            backend(`/dashboard/shipments/to-excel${page}`),
            {headers: authHeader()}
        ).then(response => {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });
    },
}