<script setup>
import { ref, onBeforeMount } from 'vue';
/* eslint-disable no-unused-vars */
import SoftBadge from '@/components/SoftBadge.vue';
import { avatar } from '../../services/UI/avatars';
import { indexCompanyUsers, showCollaborator } from '../../services/Admin';
import { useRoute } from 'vue-router';
import UserCreate from '@/views/Admin/UserCreate.vue'

/* eslint-disable no-undef */
const props = defineProps({
  companyId: {
    type: String,
    default: null
  }
});

const {
  query: {withTrashed}
} = useRoute();

const emit = defineEmits(['sendUsers'])

const collaborators = ref(null);
const filter = ref({
  search: '',
  showInactive: false,
  page: 1
});
const creating = ref(false);
const collaboratorEditing = ref({})

onBeforeMount(() => {
  if (props.companyId !== null) {
    fetch().then(emitUsers)
  }

})

const emitUsers = () => {

  emit('sendUsers', collaborators.value.data
    .map(({id, email, name}) => ({id, email, name}))
  )
}

const fetch = async () => {
  const queyFilter = {
    withTrashed: withTrashed,
    search: filter.value.search,
    showInactive: filter.value.showInactive,
    page: filter.value.search == '' ? filter.value.page : 1
  }

  const { value } = await indexCompanyUsers(
    props.companyId, queyFilter
  )

  collaborators.value = value.collaborators
}

const toggleShowInactive = () => {
  filter.value.showInactive = !filter.value.showInactive
  fetch();
}

const toPage = (page) => {
  page = page.split('page=')[1]

  if (collaborators.value.current_page != page) {
    filter.value.page = page
    fetch()
  }

}

const show = async (user) => {
  const { value: { collaborator } } = await showCollaborator(props.companyId, user);
  creating.value = true;
  collaboratorEditing.value = collaborator;
}

const handleSwitch = () => {
  creating.value = !creating.value;
  if (!creating.value) {
    collaboratorEditing.value = null;
  }
}

</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card" v-if="collaborators">
        <div class="card-header">
          <div class="row mb-3">

            <div class="col-md-8">
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>
                <input type="text" @keyup.enter="fetch" v-model.trim="filter.search" class="form-control" aria-label="Buscar ..." placeholder="Buscar ..." :disabled="creating">
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-check mx-3 my-auto">
                <input class="form-check-input" @click="toggleShowInactive" type="checkbox" value="" id="withInactive" :disabled="creating">
                <label class="form-check-label" for="withInactive">
                  Incluir inactivos
                </label>
              </div>
            </div>

            <div class="col-md-2">
              <div class="my-auto w-auto my-auto">
                <a @click="handleSwitch" class="mb-0 btn bg-gradient-success btn-sm">
                  <span v-if="!creating">+&nbsp; Agregar </span> <span v-else> &lt; Listar </span>
                </a>
              </div>
            </div>

          </div>
          <div class="row mx-1">
            <p class="text-sm m-0 p-0">Total: {{ collaborators.total }} - Página {{ collaborators.current_page }} de {{ collaborators.last_page }}, mostrando {{ collaborators.per_page }} por página. </p>
          </div>
        </div>
        <div class="table-responsive" v-if="!creating">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nombre
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Email
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Agregado el
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Activo
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody v-if="collaborators !== null">
              <tr v-if="! collaborators.data.length">
                <td colspan="5">
                  <p class="text-center">Sin datos</p>
                </td>
              </tr>
              <tr v-for="user in collaborators.data" :key="user.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="avatar me-3">
                      <img
                        :src="avatar(user.name)"
                        alt="avatar image"
                        class="null null border-radius-lg"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-secondary mb-0 text-sm">{{ user.email }}</p>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-sm">{{ user.created_at }}</span>
                </td>
                <td>
                  <SoftBadge
                    :color="user.active === null ? 'success' : 'danger'"
                    variant="gradient"
                    size="sm"
                  > {{ user.active === null ? 'Activo' : 'Inactivo' }} </SoftBadge>
                </td>
                <td class="align-middle text-center">
                  <a @click="show(user.id)"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="show company"
                    href="javascript:;"
                  >Detalles</a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5">
                    <p class="text-center">
                      <button v-for="(link, key) in collaborators.links" :key="`pagination_${key}`"
                        class="btn btn-sm w-auto mx-1 p-2"
                        :disabled="!link.url"
                        @click="toPage(link.url)"
                        :class="link.active ? 'bg-gradient-dark' : 'bg-gradient-secondary' "
                        v-html="link.label"
                      ></button>
                    </p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <UserCreate v-else :editUser="collaboratorEditing" />
      </div>
    </div>
  </div>
</template>
