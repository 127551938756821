import { useFetch } from "../Http";

const backend = (query = '') => `${process.env.VUE_APP_API_BASE_URL}/dashboard/not-purchased${query}`;

const getNotPurchased = async(filter) => { // {user, from, to}

    const query = '?' + new URLSearchParams(filter).toString()

    const { data, error } = await useFetch(
        backend(`${query}`),
    );

    return {data, error};
}

export default getNotPurchased