<template>
  <!-- {{ { name: 'Company', params: {id: profile.company.company_id } } }} -->
  <div v-if="profile"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">

      <li class="mt-3 nav-item d-flex justify-content-center">
        <router-link
            :to="{name: 'Dashboard.Quotes'}"
            class="mb-0 btn bg-gradient-success btn-block w-80"
          >
            +&nbsp; Nueva Guía
          </router-link>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Usuario
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Editar perfil" :to="{ name: 'Profile' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item" v-if="isSuperAdmin">
        <sidenav-collapse navText="Administrar usuarios" :to="{ name: 'Users' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Accesos
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse v-if="isSuperAdmin" navText="No adquiridas" :to="{ name: 'NotPurchased' }">
          <template #icon>
          <Box3d/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="isSuperAdmin">
        <sidenav-collapse navText="Empresas" :to="{ name: 'Companies' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>

      </li>
      <li class="nav-item" v-if="isAdmin">
        <sidenav-collapse navText="Empresa" :to="{ name: 'Company', params: {id: profile.company.company_id } }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse v-if="isSuperAdmin" navText="Pagos" :to="{ name: 'Billing' }">
          <template #icon>
            <Document/>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item">
        <sidenav-collapse v-if="isSuperAdmin" navText="Tables" :to="{ name: 'Tables' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer" v-if="isUser">
    <sidenav-card
      :class="cardBg"
      textPrimary="Quieres enviar y facturar a fin de mes?"
      textSecondary="Actualiza tu cuenta a empresa, contacta con ventas"
      route="#"
      @click="contactWithSales"
      label="Ventas"
      icon="ni ni-diamond"
    />
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Switches from "../../components/Icon/Switches.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Document from "../../components/Icon/Document.vue";

const phone = process.env.VUE_APP_SUPPORT_WHATS;
const appName = process.env.VUE_APP_NAME;

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    SidenavCard,
    Shop,
    Office,
    CustomerSupport,
    // Switches,
    Box3d,
    Document,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    contactWithSales() {
      const user = this.profile.name;
      const email = this.profile.email;

      let message = `Hola, soy ${user}, usuario de ${appName}. Estoy interesado en el plan para empresas, podría contactarme a ${email}?`;
      message = encodeURIComponent(message)
      const url = `https://wa.me/52${phone}?text=${message}`
      window.open(url, "_blank");
    }
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    isUser() {
      return this.$store.getters["profile/isUser"];
    },
    isSuperAdmin() {
      return this.$store.getters["profile/isSuperAdmin"];
    },
    isAdmin() {
      return this.$store.getters["profile/isAdmin"];
    },
  },
};
</script>
