<template>
  <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a
            href="https://www.facebook.com/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a
            href="https://twitter.com/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }}
            <spann
              class="font-weight-bold"
              >EnviOy</spann>
          </p>
        </div>

        <div class="mx-auto mt-4 text-center col-lg-8">
          <a
            :href="`${landing}terminos-y-condiciones`"
            target="_blank"
            class="mb-2 text-secondary text-sm me-xl-5 me-3 mb-sm-0"
          >
            Términos y condiciones
          </a>
          <a
            :href="`${landing}aviso-de-privacidad`"
            target="_blank"
            class="mb-2 text-secondary text-sm me-xl-5 me-3 mb-sm-0"
          >
            Aviso de privacidad
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const landing = process.env.VUE_APP_LANDING_URL;
export default {
  name: "app-footer",
  data() {
    return {
      landing
    }
  },
};
</script>
