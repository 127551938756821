<script setup>
/* eslint-disable no-unused-vars */
import SoftBadge from '@/components/SoftBadge.vue';
import CompanyCreate from './CompanyCreate.vue';
import { company as avatar } from '@/services/UI/avatars'
import { indexCompanies } from '@/services/Admin'
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

const creating = ref(false);
const dataCompanies = ref([]);
const pagination = ref([])
const filter = ref({
  page: 1,
  name: '',
  showInactive: false
})

onMounted(() => {
  fetch();
});

const toPage = (url = '') => {
  const page = url.split('page=')[1]
  filter.value.page = page

  fetch()
}

const toggleShowInactive = () => {
  filter.value.showInactive = !filter.value.showInactive
  fetch();
}

const fetch = async () => {
  const queyFilter = {
    page: filter.value.search == '' ? filter.value.page : 1,
    name: filter.value.name,
    showInactive: filter.value.showInactive,
    paginated: true
  }

  const { value } = await indexCompanies(queyFilter)

  dataCompanies.value = value;
  pagination.value = value.links;
}

const showCompany = (company, isActive) => {
  const path =  { name: 'Company', params: { id: company }}

  if (!isActive) {
    path.query = { withTrashed: true }
  }

  router.push(path)
}

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">

      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-2">
                <h6>Empresas</h6>
              </div>
              <div class="col-8">
                <div class="d-flex justify-content-around">
                  <!-- Searcher -->
                  <div class="input-group mb-3 w-80">
                    <span class="input-group-text">
                      <i class="fas fa-search" aria-hidden="true"></i>
                    </span>
                    <input type="text" @keyup.enter="fetch" v-model="filter.name" class="form-control" aria-label="Buscar por nombre" placeholder="Buscar por nombre">
                  </div>
                  <!-- Filter active -->
                  <div class="form-check  my-auto">
                    <input class="form-check-input" @click="toggleShowInactive" v-model="filter.showInactive" type="checkbox" value="" id="withInactive">
                    <label class="form-check-label" for="withInactive">
                      Incluir inactivos
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <!-- Button new company -->
                <div class="my-auto w-auto ms-auto">
                  <a @click="creating = !creating" class="mb-0 btn bg-gradient-success btn-sm">
                  <span v-if="!creating">+&nbsp; Nueva empresa</span> <span v-else> &lt; Listar </span>
                  </a>
                </div>
              </div>
            </div>
            <p class="text-sm m-0 p-0">Total: {{ dataCompanies.total }} - Página {{ dataCompanies.current_page }} de {{ dataCompanies.last_page }}, mostrando {{ dataCompanies.per_page }} por página. </p>

          </div>
          <div v-if="!creating" class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Nombre
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                      Administrador
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Status
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Desde
                    </th>
                    <th class="text-secondary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="company in dataCompanies.data" :key="company.company_id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img :src="avatar(company.name)" class="avatar avatar-sm me-2" :alt="company.name">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ company.name }} - {{ company.company_id }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ company.phone }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">{{ company.admin_user.name }}</p>
                      <p class="text-xs text-secondary mb-0">{{ company.admin_user.email }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <SoftBadge
                        :color="company.active ? 'success' : 'danger'"
                        variant="gradient"
                        size="sm"
                      > {{ company.active ? 'Activo' : 'Inactivo' }} </SoftBadge>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ company.created_at }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <a @click="showCompany(company.company_id, company.active)"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="show company"
                        href="javascript:;"
                      >Detalles</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="5">
                        <p class="text-end" id="pagination_content">
                          <button v-for="link, key in pagination"
                            :key="`pagination_${key}`"
                            href="#"
                            @click="toPage(link.url)"
                            v-html="link.label"
                            class="btn btn-sm w-auto mx-1 p-2"
                            :disabled="!link.url"
                            :class="link.active ? 'bg-gradient-dark' : 'bg-gradient-secondary' "
                          ></button>
                        </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div v-else>
            <CompanyCreate />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

