import { useFetch } from "../Http";
import getNotPurchased from "./notPurchased";

const backend = (uri = '') => `${process.env.VUE_APP_API_BASE_URL}/b2b${uri}`;

const getQuery = (object) => {
    if (!object.showInactive) {
        delete(object.showInactive)
    }

    if (!object.withTrashed) {
        delete(object.withTrashed)
    }

    return '?' + new URLSearchParams(object).toString()
}

/* COMPANIES */

const createCompany = async (payload) => {
    const { data, error } = await useFetch(backend(`/companies`), 'post', payload);

    return { data, error };
}

const indexCompanies = async (query = {}) => {

    query = getQuery(query)

    const { data } = await useFetch(backend(`/companies${query}`));

    return data;
}

const showCompany = async (companyId, withTrashed) => {
    const { data } = await useFetch(backend(`/companies/${companyId}${withTrashed ? '?withTrashed': ''}`));

    return data;
}

const updateCompany = async(companyId, body) => {
    const { error } = await useFetch(
        backend(`/companies/${companyId}`),
        'put', body
    );

    return error;
}

const deleteCompany = async(companyId) => {
    const { error } = await useFetch(
        backend(`/companies/${companyId}`),
        'delete'
    );

    return error;
}

const reactiveCompany = async(companyId) => {

    const { error } = await useFetch(
        backend(`/companies/${companyId}/reactivate`),
        'put'
    );

    return error;
}

/* USERS */

const indexCompanyUsers = async (companyId, query = {}) => {

    query = getQuery(query)
    const { data } = await useFetch(backend(`/companies/${companyId}/collaborators${query}`));

    return data;
}

const allUsers = async (query) => {
    query = getQuery(query);
    const { data } = await useFetch(`${process.env.VUE_APP_API_BASE_URL}/admin/users${query}`);

    return data;
}

const createCollaborator = async (companyId, payload) => {
    const { data, error } = await useFetch(
        backend(`/companies/${companyId}/collaborators`),
        'post', payload
    );

    return { data, error };
}

const showCollaborator = async (company, user) => {
    const { data } = await useFetch(
        backend(`/companies/${company}/collaborators/${user}`),
    );

    return data;
}

const updateCollaborator = async (company, user, payload) => {
    const { error } = await useFetch(
        backend(`/companies/${company}/collaborators/${user}`),
        'put', payload
    );

    return error;
}

const deleteCollaborator = async (company, user) => {
    const { error } = await useFetch(
        backend(`/companies/${company}/collaborators/${user}`),
        'delete'
    );

    return error;
}

const reactivateCollaborator = async (company, user) => {
    const { error } = await useFetch(
        backend(`/companies/${company}/collaborators/${user}/reactivate`),
        'put'
    );

    return error;
}

export {
    createCompany, indexCompanies, showCompany, updateCompany, deleteCompany, reactiveCompany,
    indexCompanyUsers, createCollaborator, showCollaborator, updateCollaborator, deleteCollaborator, reactivateCollaborator,
    allUsers,

    /* --- */
    getNotPurchased,
}