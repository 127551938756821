import * as yup from "yup";

const useUpdateCompanySchema = () => yup.object({
    name: yup.string().required('El nombre es requerido'),
    phone: yup.string().matches(/^\d+$/, 'El teléfono debe contener sólo números').length(10, 'El teléfono debe contener 10 números').required('El teléfono es requerido'),
    address: yup.string().required('La dirección es requerida'),
});

const useCreateUserSchema = () => yup.object({
  name: yup.string().required('El nombre es requerido'),
  email: yup.string().required('El coreo es requerido').email('El campo no es un correo válido'),
})

export {
  useUpdateCompanySchema,
  useCreateUserSchema,
}