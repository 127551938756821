<template>
  <CardContainer
    title="Adquirir nueva guía"
    description="Búsqueda y selección"
    v-if="user"
  >

  <Searcher :isDisabled="disableSearcher" v-if="user.company?.deleted_at === null || (user.roles == 1 || user.roles == 4)" />
  <AccountDeactivated v-else />

  <div class="row justify-content-center mb-3 mt-5" v-if="(step === 4 && !guide) && user.roles !== 4">
    <div class="mt-4 col-lg-8 col-12 mt-lg-0">
        <div class="card">
            <div class="p-3 pb-0 card-header">
                <div class="row">
                    <div class="col-8 d-flex">
                        <div><img :src="avatar(user.name)" class="avatar avatar-sm me-2" alt="avatar image"></div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                            <p class="text-xs">{{ user.company?.name }}</p>
                        </div>
                    </div>
                    <!-- <div class="col-4"><span class="badge ms-auto float-end bg-gradient-success">Recommendation</span></div> -->
                </div>
            </div>
            <div class="p-3 pt-1 card-body">

                <div class="row">

                  <div class="col-6">
                    <h6>Modelo de Pago B2B</h6>
                    <p class="text-sm"> Este envío será registrado para la facturación mensual de tu empresa. </p>
                    <p class="text-sm">Los colaboradores/administradores pueden realizar envíos sin pago inmediato; los costos acumulados se liquidarán al final del mes.</p>
                    <p class="text-sm">La factura detallada estará disponible para su revisión y pago.</p>

                    <button class="btn bg-gradient-secondary w-auto mb-5" @click="step--" :disabled="isLoading">
                      Anterior
                    </button>

                    <div class="p-3 bg-gray-100 d-flex border-radius-lg">
                      <h4 class="my-auto">
                        <span class="text-sm text-secondary me-1">$</span> {{ search.quote.price.replace('$', '') }}
                      </h4>
                      <button @click="handleSubmitB2B"
                        :disabled="isLoading"
                        class="mb-0 btn btn-outline-dark ms-auto"
                      >
                        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Adquirir
                      </button>
                    </div>
                  </div>
                  <div class="col-6 mb-5">
                    <ShipmentDetails />
                  </div>

                </div>
            </div>
        </div>
    </div>
  </div>

  <div v-else>
    <div v-if="search.quote && !guide" class="row justify-content-center" id="formWizard">
      <div class="col-10 card p-3 border">
        <Form
          role="form"
          class="text-start"
          :validation-schema="shipperSchema"
          @submit="() => step++"
          v-if="step === 1"
        >
          <div class="p-3">
              <h4 class="text-primary">Datos de Origen</h4>
              <p class="fw-bold">
                {{ search.locationsInfo.shipper.colonia }}, {{ search.locationsInfo.shipper.cveestado }} - {{ search.locationsInfo.shipper.cp }}
              </p>

              <div class="row g-3 mb-3">
                  <div class="col-md-6 col-12">
                      <label for="shipperName" class="form-label">Nombre de contacto</label>
                      <soft-field
                        autocomplete="off"
                        id="shipperName"
                        v-model="shipper.shipperName"
                        type="text"
                        placeholder="Nombre de contacto"
                        name="shipperName"
                      />
                  </div>
                  <div class="col-md-6 col-12">
                      <label for="shipperPhone" class="form-label">Teléfono de contacto</label>
                      <soft-field
                        id="shipperPhone"
                        v-model="shipper.shipperPhone"
                        type="number"
                        placeholder="Teléfono de contacto"
                        name="shipperPhone"
                        maxlength="10"
                        @keypress="isNumber($event)"
                      />
                  </div>
              </div>
              <div class="row g-3 mb-3">
                  <div class="col-md-6 col-12">
                      <label for="shipperAddress" class="form-label">Dirección (calle)</label>
                      <soft-field
                        id="shipperAddress"
                        v-model="shipper.shipperAddress"
                        type="text"
                        placeholder="Nombre de la calle"
                        name="shipperAddress"
                      />
                  </div>
                  <div class="col-md-6 col-12">
                      <label for="shipperAddressNumber" class="form-label">Dirección (número)</label>
                      <soft-field
                        id="shipperAddressNumber"
                        v-model="shipper.shipperAddressNumber"
                        type="text"
                        placeholder="S/N"
                        name="shipperAddressNumber"
                      />
                  </div>
              </div>
              <div class="row g-3 mb-3">
                  <div class="col-md-6 col-12">
                      <label for="shipperReference" class="form-label">Referencia</label>
                      <soft-field
                        id="shipperReference"
                        v-model="shipper.shipperReference"
                        type="text"
                        placeholder="A 100 mts de la tienda"
                        name="shipperReference"
                      />
                  </div>
                  <div class="col-md-6 col-12">
                      <label for="shipperCompany" class="form-label">Empresa (Opcional)</label>
                      <input type="text" class="form-control" name="shipperCompany" v-model="shipper.shipperCompany" placeholder="Empresa">
                  </div>
              </div>

              <!-- :class=" search?.quote?.require_package_declaration ? '' : 'd-none' " -->
              <div class="row g-3 mb-3" v-show="search?.quote?.require_package_declaration">
                  <div class="col-md-6 col-12">
                      <label for="shipmentDescription" class="form-label">Contenido del paquete</label>
                      <!-- <textarea maxlength="200" rows="3" cols="" class="form-control" name="shipmentDescription" v-model="shipper.shipmentDescription" placeholder="Contenido del paquete"></textarea> -->
                      <Field as="textarea" name="shipmentDescription" class="form-control" id="shipmentDescription" cols="" rows="3" v-model="shipper.shipmentDescription" maxlength="200"/>
                      <ErrorMessage
                        name="shipmentDescription"
                        class="error-feedback text-xs text-danger"
                        role="alert"
                      />
                  </div>
                  <div class="col-md-6 col-12">
                      <label for="shipmentDeclaredValue" class="form-label">Valor estimado del paquete (MXN)</label>
                      <!-- <input type="text" class="form-control" name="shipmentDeclaredValue" v-model="shipper.shipmentDeclaredValue" @keypress="isNumber($event)" placeholder="100"> -->
                      <Field type="text" class="form-control" name="shipmentDeclaredValue" v-model="shipper.shipmentDeclaredValue" @keypress="isNumber($event)" placeholder="100"/>
                      <ErrorMessage
                        name="shipmentDeclaredValue"
                        class="error-feedback text-xs text-danger"
                        role="alert"
                      />
                      <p class="text-xs text-muted">Si no deseas asegurar tu envío indica $0 pesos en el valor</p>
                  </div>

                  <small>
                      Seguro por el 1% del valor declarado. En caso de daño o pérdida te reembolsaremos el monto ingresado en 3 días hábiles.
                      Al hacer uso del servicio,
                      <a href="">aceptas los términos y condiciones</a>
                      . Valor máximo del reembolso es de $20000 MXN.
                  </small>
              </div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn bg-gradient-secondary m-2">
              Siguiente
            </button>
          </div>
        </Form>

        <Form
          role="form"
          class="text-start"
          :validation-schema="recipientSchema"
          @submit="() => step++"
          v-if="step === 2"
        >
          <div class="p-3">
            <h4 class="text-primary">Datos de Destino</h4>
            <p class="fw-bold">
                {{ search.locationsInfo.recipient.colonia }}, {{ search.locationsInfo.recipient.cveestado }} - {{ search.locationsInfo.recipient.cp }}
            </p>

            <div class="row g-3 mb-3">
                <div class="col-md-6 col-12">
                    <label for="recipientName" class="form-label">Nombre de contacto</label>
                    <soft-field
                      id="recipientName"
                      v-model="recipient.recipientName"
                      type="text"
                      placeholder="Nombre de contacto"
                      name="recipientName"
                    />
                </div>
                <div class="col-md-6 col-12">
                    <label for="recipientPhone" class="form-label">Teléfono de contacto</label>
                    <soft-field
                      id="recipientPhone"
                      v-model="recipient.recipientPhone"
                      type="text"
                      placeholder="Teléfono de contacto"
                      name="recipientPhone"
                      @keypress="isNumber($event)"
                    />
                </div>
            </div>
            <div class="row g-3 mb-3">
                <div class="col-md-6 col-12">
                    <label for="recipientAddress" class="form-label">Dirección (calle)</label>
                    <soft-field
                      id="recipientAddress"
                      v-model="recipient.recipientAddress"
                      type="text"
                      placeholder="Nombre de la calle"
                      name="recipientAddress"
                    />
                </div>
                <div class="col-md-6 col-12">
                    <label for="recipientAddressNumber" class="form-label">Dirección (número)</label>
                    <soft-field
                      id="recipientAddressNumber"
                      v-model="recipient.recipientAddressNumber"
                      type="text"
                      placeholder="S/N"
                      name="recipientAddressNumber"
                    />
                </div>
            </div>
            <div class="row g-3 mb-3">
                <div class="col-md-6 col-12">
                    <label for="recipientReference" class="form-label">Referencia</label>
                    <soft-field
                      id="recipientReference"
                      v-model="recipient.recipientReference"
                      type="text"
                      placeholder="A 100 mts de la tienda"
                      name="recipientReference"
                    />
                </div>
                <div class="col-md-6 col-12">
                    <label for="recipientCompany" class="form-label">Empresa (Opcional)</label>
                    <soft-field
                      id="recipientCompany"
                      v-model="recipient.recipientCompany"
                      type="text"
                      placeholder="Empresa"
                      name="recipientCompany"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn bg-gradient-secondary m-2" @click="step--">
                Anterior
              </button>
              <button type="submit" class="btn bg-gradient-secondary m-2">
                Siguiente
              </button>
            </div>
          </div>
        </Form>

        <div v-if="step === 3">
          <div class="p-3">
            <h4 class="text-primary">Resumen de compra</h4>

            <small>Por favor verifica que la información es correcta, puedes regresar y modificar cualquier dato.</small>

            <h3 class="mt-3 mx-3">{{ search.quote.shipping_type }}</h3>

            <div class="row mb-3">
              <div class="col-md-6">
                <div class="p-3">
                  <p class="">
                    {{ search.quote.shipping_provider.name }} -
                    <span class="text-xs text-secondary">({{ search.quote.delivery_date }})</span>
                  </p>

                  <p class="mb-0">

                  </p>

                  <table class="">
                    <tr>
                      <th colspan="4">
                        Detalles del paquete
                      </th>
                    </tr>
                    <tr>
                      <td>Alto: {{ search.pkg.high }} cm</td>
                      <td>Ancho: {{ search.pkg.width }} cm</td>
                      <td>Largo: {{ search.pkg.long }} cm</td>
                      <td>Peso: {{ search.pkg.mass }} kg</td>
                    </tr>
                    <tr>
                      <td colspan="2">Contenido: {{ shipper.shipmentDescription }}</td>
                      <td colspan="2">Valor declarado: {{ currency(shipper.shipmentDeclaredValue) }}</td>
                    </tr>
                    <tr v-if="shipper.shipmentDeclaredValue > 0">
                      <th class="text-primary" colspan="4">
                        El envío será asegurado
                      </th>
                    </tr>
                  </table>

                </div>
              </div>

              <div class="col-md-6">
                <div class="p-3">
                  <table class="table m-0">
                    <tr>
                      <th>Precio al público</th>
                      <td>{{ search.quote.public_price }}</td>
                    </tr>
                    <tr>
                      <th>Precio con EnviOy</th>
                      <td>{{ search.quote.price }}</td>
                    </tr>
                    <tr>
                      <th>Ahorras</th>
                      <td>{{ saved(search.quote.public_price, search.quote.price) }}</td>
                    </tr>
                  </table>

                </div>
              </div>
            </div>

            <div class="row justify-content-around mb-5">
              <!-- <div class="col-md-3">
                <div class="card p-3">
                  <div class="bg-primary text-white text-center mx-3 p-1 mb-3 rounded">Dimensiones</div>
                  <table class="table">
                    <tr>
                      <th>Alto</th>
                      <td>{{ search.pkg.high }} CM</td>
                    </tr>
                    <tr>
                      <th>Ancho</th>
                      <td>{{ search.pkg.width }} CM</td>
                    </tr>
                    <tr>
                      <th>Largo</th>
                      <td>{{ search.pkg.long }} CM</td>
                    </tr>
                    <tr>
                      <th>Peso</th>
                      <td>{{ search.pkg.mass }} KG</td>
                    </tr>
                  </table>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="card p-3">
                  <div class="bg-primary text-white text-center mx-3 p-1 mb-3 rounded">Se envía de</div>
                  <table class="table">
                    <tr>
                      <th>Calle</th>
                      <td>{{ shipper.shipperAddress }}, {{ shipper.shipperAddressNumber }}. {{ shipper.shipperReference }}.</td>
                    </tr>
                    <tr>
                      <th>Empresa</th>
                      <td>{{ shipper.shipperCompany == '' ? 'Sin información' : shipper.shipperCompany }}</td>
                    </tr>
                    <tr>
                      <th>Contacto</th>
                      <td>{{ shipper.shipperName }}, {{ shipper.shipperPhone }}</td>
                    </tr>
                    <tr>
                      <th>Colonia</th>
                      <td>{{ search.locationsInfo.shipper.colonia }}</td>
                    </tr>
                    <tr>
                      <th>Municipio</th>
                      <td>{{ search.locationsInfo.shipper.municipio }} - {{ search.locationsInfo.shipper.cp }}</td>
                    </tr>
                    <tr>
                      <th>Estado</th>
                      <td>{{ search.locationsInfo.shipper.estado }} - {{ search.locationsInfo.shipper.pais }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card p-3">
                  <div class="bg-primary text-white text-center mx-3 p-1 mb-3 rounded">Llegará a</div>
                  <table class="table">
                    <tr>
                      <th>Calle</th>
                      <td>{{ recipient.recipientAddress }}, {{ recipient.recipientAddressNumber }}. {{ recipient.recipientReference }}.</td>
                    </tr>
                    <tr>
                      <th>Empresa</th>
                      <td>{{ recipient.recipientCompany == '' ? 'Sin información' : recipient.recipientCompany }}</td>
                    </tr>
                    <tr>
                      <th>Contacto</th>
                      <td>{{ recipient.recipientName }}, {{ recipient.recipientPhone }}</td>
                    </tr>
                    <tr>
                      <th>Colonia</th>
                      <td>{{ search.locationsInfo.recipient.colonia }}</td>
                    </tr>
                    <tr>
                      <th>Municipio</th>
                      <td>{{ search.locationsInfo.recipient.municipio }} - {{ search.locationsInfo.recipient.cp }}</td>
                    </tr>
                    <tr>
                      <th>Estado</th>
                      <td>{{ search.locationsInfo.recipient.estado }} - {{ search.locationsInfo.recipient.pais }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary m-2" @click="step--">
                <i class="bi bi-caret-left-fill"></i>
                Anterior
              </button>
              <button type="submit" class="btn btn-primary m-2" @click="step++">
                <i class="bi bi-credit-card-2-back-fill"></i>
                Pagar
              </button>
            </div>
          </div>
        </div>

        <Form
          role="form"
          class="text-start"
          :validation-schema="paymentSchema"
          @submit="handleSubmit"
          v-if="step === 4"
          id="form_payment"
        >
          <div class="p-3">
            <h4 class="text-primary">Pago</h4>
            <p class="fw-bold"> {{ search.quote.price }} (IVA Incluido)</p>
            <div class="row mb-3">
                <div class="col-md-8 col-12">

                    <label for="paymentEmail" class="form-label">Correo electrónico (Aquí se enviará tu guía.)</label>
                    <Field
                      type="email"
                      class="form-control"
                      name="paymentEmail"
                      placeholder="mi_correo@gmail.com"
                      autocomplete="off"
                      v-model="payment.paymentEmail"
                      disabled
                    />
                    <ErrorMessage name="paymentEmail" class="error-feedback text-xs text-danger"/>

                    <label for="paymentName" class="form-label">Nombre completo</label>
                    <Field
                      type="text"
                      class="form-control"
                      name="paymentName"
                      id="paymentName"
                      placeholder="Nombre"
                      autocomplete="off"
                      data-openpay-card="holder_name"
                      v-model="payment.paymentName"
                      disabled
                    />
                    <ErrorMessage name="paymentName" class="error-feedback text-xs text-danger"/>

                    <div class="col-12 d-flex justify-content-between px-3 mt-3">
                      <p class="">Pagar con </p>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="paymentPaymentType" v-model="payment.paymentPaymentType" value="1" checked disabled>
                          <label class="form-check-label" for="paymentPaymentTypeCard">
                              Tarjeta
                          </label>
                      </div>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="paymentPaymentType" v-model="payment.paymentPaymentType" value="2" disabled>
                          <label class="form-check-label" for="paymentPaymentTypePayPal">
                              PayPal
                          </label>
                      </div>
                      <ErrorMessage name="paymentPaymentType" class="error-feedback text-xs text-danger"/>
                    </div>

                    <div id="cardPayment" class="row p-0 m-0">
                        <div class="col-6">
                            <label for="paymentCardNumber" class="form-label">Número de Tarjeta:</label>
                            <Field
                              type="text"
                              id="paymentCardNumber"
                              class="form-control"
                              name="paymentCardNumber"
                              placeholder="XXXX-XXXX-XXXX-XXXX"
                              required autocomplete="off" data-openpay-card="card_number"
                              v-model="payment.card_"
                              maxlength="16"
                              @keypress="isNumber($event, 16)"
                            />

                            <ErrorMessage name="paymentCardNumber" class="error-feedback text-xs text-danger"/>
                        </div>

                        <div class="col-4">
                            <div class="row g-0">
                                <label for="paymentCardNumber" class="form-label">Vigencia:</label>
                                <div class="col-6">
                                    <Field
                                      type="text"
                                      id="paymentCardMonth"
                                      class="form-control"
                                      name="paymentCardMonth"
                                      placeholder="MM"
                                      required data-openpay-card="expiration_month"
                                      v-model="payment.month_"
                                      maxlength="2"
                                      @keypress="isNumber($event, 2)"
                                    />

                                    <ErrorMessage name="paymentCardMonth" class="error-feedback text-xs text-danger"/>
                                </div>
                                <div class="col-6">
                                    <Field
                                      type="text"
                                      id="paymentCardYear"
                                      class="form-control"
                                      name="paymentCardYear"
                                      placeholder="AA"
                                      required data-openpay-card="expiration_year"
                                      v-model="payment.year_"
                                      maxlength="2"
                                      @keypress="isNumber($event, 2)"
                                    />

                                    <ErrorMessage name="paymentCardYear" class="error-feedback text-xs text-danger"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label for="paymentCardCvv" class="form-label">CVV/CVC:</label>
                            <Field
                              type="text"
                              id="paymentCardCvv"
                              class="form-control"
                              name="paymentCardCvv"
                              placeholder="XXX"
                              required autocomplete="off" data-openpay-card="cvv2"
                              v-model="payment.cvs_"
                              maxlength="3"
                              @keypress="isNumber($event, 3)"
                            />
                            <ErrorMessage name="paymentCardCvv" class="error-feedback text-xs text-danger"/>
                        </div>

                        <input type="hidden" name="deviceSessionId">
                        <input type="hidden" name="token_id" id="token_id">
                    </div>

                    <div id="payPalPayment" class="row p-0 m-0 d-none">
                        <h4>PayPal</h4>
                    </div>
                </div>

                <div class="col-md-4 col-12 p-0">
                  <ShipmentDetails />
                </div>

                <button type="submit" class="btn btn-primary btn-lg mt-3 btn-block" :disabled="isLoading">
                  Pagar ahora
                  <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn bg-gradient-secondary m-2" @click="step--" :disabled="isLoading">
                Anterior
              </button>
            </div>
          </div>
        </Form>

      </div>
    </div>
  </div>


  <div v-if="guide" class="row justify-content-center mb-5">
    <div class="col-md-8 col-12 card px-5 py-3 box-shadow border-0">
      <div v-if="!guide.error">
        <h4 class="mb-5">Compra Exitosa !</h4>

        <div class="border rounded p-3 mb-3">
          <table class="w-100 pb-3">
            <tr style="border-bottom: 1px solid #ccc">
              <th colspan="2">
                <div class="text-center my-2">Tu folio de compra: <span class="text-primary">{{ guide.folio }}</span></div>
              </th>
            </tr>
            <!-- Destinos -->
            <tr class="bg-dark text-white">
              <td class="p-1 m-1" width="50%"><div class="text-center">Se va de</div></td>
              <td class="p-1 m-1" width="50%"><div class="text-center">a</div></td>
            </tr>
            <tr>
              <td class="p-1 m-1">{{ search?.locationsInfo.shipper.colonia }} - {{ search?.locationsInfo.shipper.cp }}</td>
              <td class="p-1 m-1">{{ search?.locationsInfo.recipient.colonia }} - {{ search?.locationsInfo.recipient.cp }}</td>
            </tr>
            <!-- Servicio, Precio -->
            <tr class="bg-dark text-white">
              <td class="p-1 m-1"><div class="text-center">Tipo de Servicio</div></td>
              <td class="p-1 m-1"><div class="text-center">Monto</div></td>
            </tr>
            <tr>
              <td class="p-1 m-1">{{ search?.quote.shipping_provider.name }} - {{ search?.quote.shipping_type }}</td>
              <td class="p-1 m-1"> <div class="text-end mx-1">{{ guide.price }} MXN</div> </td>
            </tr>
          </table>
        </div>

        <p>Felicidades, tu compra ha sido exitosa. Recibirás por correo electrónico tu guía de envío detallada y la confirmación de tu orden. Solo falta llevar tu paquete a la paquetería seleccionada.</p>
        <p class="text-xs">Tu guía se ha enviado al correo electrónico proporcionado, si no lo vez, puedes buscarlo en la sección de spam.</p>

        <div v-if="guide.locations.length">
            <strong class="text-sm mb-1">Mostradores más cercanos:</strong>
            <ul class="list-group list-group-flush" id="mostradores">
              <li class="list-group-item" v-for="loc in guide.locations" :key="loc.geoLocation">
                  <p class="text-xs mt-1 mb-1">{{ loc.name }}</p>
                  <div class="d-flex justify-content-between">
                      <p class="text-xs m-0">Dirección: {{ loc.address }}</p>
                      <a class="text-xs mx-2 w-20 text-end" :href="loc.geoLocation" target="_blank" rel="noopener noreferrer">
                          <svg width="16px" height="16px" viewBox="0 -0.28 46.384 46.384" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Group_47" data-name="Group 47" transform="translate(-369.028 -1786.405)"> <path id="Path_126" data-name="Path 126" d="M384.789,1824.733l-13.761,5.5v-36.329l13.761-5.5Z" fill="#ffffff" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"></path> <path id="Path_127" data-name="Path 127" d="M413.412,1824.733l-13.761,5.5v-36.329l13.761-5.5Z" fill="#ffffff" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"></path> <path id="Path_128" data-name="Path 128" d="M385.34,1824.733l13.761,5.5v-36.329l-13.761-5.5Z" fill="#d1d3d4" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"></path> </g> </g></svg>
                          ver en Maps
                      </a>
                  </div>
                  <p class="text-xs m-0">Horario: {{ loc.horary }}</p>
              </li>
            </ul>
        </div>
        <strong v-else class="text-sm mb-1">No se encontraron mostradores cercanos.</strong>

        <div class="row mb-3 mt-5 justify-content-around">
          <a :href="guide.url" target="_blank" rel="noopener noreferrer" class="col-4 btn bg-gradient-success btn-lg w-auto">
            Descargar
          </a>
          <button type="button" class="col-4 btn bg-gradient-secondary btn-lg w-auto" @click="reload">
            ¿Nuevo envío?
          </button>
        </div>
      </div>

      <div v-else class="row">
        <h4 class="mb-5 text-danger text-center">¡Oops! Algo salió mal {{ paymentError ? 'con el proceso de pago!' : '!' }}</h4>
        <div v-html="guide.error"></div>
        <button type="button" class="btn bg-gradient-secondary btn-lg mt-3 btn-block mx-auto" @click="reload">
          Cargar nuevamente el corizador.
        </button>
      </div>
    </div>
  </div>

  </CardContainer>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useStore } from 'vuex';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import CardContainer from './templates/CardContainer.vue'
import Searcher from '@/views/components/QuotesPage/Searcher.vue'
import SoftField from "@/components/SoftField.vue";
import services from "@/services/Search/index.js";
import { avatar } from "@/services/UI/avatars.js";
import ShipmentDetails from '@/views/components/QuotesPage/ShipmentDetails.vue';
import AccountDeactivated from "../components/AccountDeactivated.vue";

const vNumber = yup.number()
  .transform((value) => (isNaN(value) ? undefined : value))
  // .positive('Debe ser un número positivo')
  .integer('Debe ser un número entero');

const requiredMessage = 'El campo es requerido';

const shipperSchema = yup.object().shape({
  shipperName          : yup.string().required(requiredMessage),
  shipperPhone         : yup.string().required(requiredMessage).length(10, 'El campo debe contener 10 cráteres'),
  shipperAddress       : yup.string().required(requiredMessage),
  shipperAddressNumber : yup.string().required(requiredMessage),
  shipperReference     : yup.string(),
  shipperCompany       : yup.string(),
  shipmentDescription  : yup.string().required(requiredMessage),
  shipmentDeclaredValue: vNumber.required(requiredMessage)
});

const recipientSchema = yup.object().shape({
  recipientName          : yup.string().required(requiredMessage),
  recipientPhone         : yup.string().required(requiredMessage).length(10, 'El campo debe contener 10 cráteres'),
  recipientAddress       : yup.string().required(requiredMessage),
  recipientAddressNumber : yup.string().required(requiredMessage),
  recipientReference     : yup.string().required(requiredMessage),
  recipientCompany       : yup.string()
});

const paymentSchema = yup.object().shape({
  // paymentPaymentType: yup.string().required(requiredMessage),
  paymentEmail      : yup.string().email('El campo debe ser un email válido').required(requiredMessage),
  paymentName       : yup.string().required(requiredMessage),
  paymentCardNumber : yup.string().required(requiredMessage).length(16, 'El campo debe contener 16 cráteres'),
  paymentCardMonth  : yup.string().required(requiredMessage).length(2, 'El campo debe contener 3 cráteres'),
  paymentCardYear   : yup.string().required(requiredMessage).length(2, 'El campo debe contener 3 cráteres'),
  paymentCardCvv    : yup.string().required(requiredMessage).length(3, 'El campo debe contener 2 cráteres'),
});

export default {
  components: { CardContainer, Searcher, Form, SoftField, Field, ErrorMessage, ShipmentDetails, AccountDeactivated },
  setup() {

    const store     = useStore();
    const step      = ref(1);
    const isLoading = ref(false);
    const disableSearcher = ref(false);
    /* Form */
    const shipper = ref({
      shipperName          : '',
      shipperPhone         : '',
      shipperAddress       : '',
      shipperAddressNumber : '',
      shipperReference     : '',
      shipperCompany       : '',
      shipmentDescription  : 'N/A',
      shipmentDeclaredValue: null
    });
    const recipient = ref({
      recipientName         : '',
      recipientPhone        : '',
      recipientAddress      : '',
      recipientAddressNumber: '',
      recipientReference    : '',
      recipientCompany      : ''
    });
    const payment = ref({
      paymentPaymentType: '1',
      paymentEmail      : '',
      paymentName       : '',
      deviceSessionId   : '',
      token_id          : '',
      card_ : process.env.VUE_APP_OPENPAY_TEST_CARD,
      month_: '',
      year_ : '',
      cvs_  : '',
    });

    const guide = ref(null);
    const paymentError = ref(false)

    const getDeviceSessionId = () => {
        // sistema de fraude
        window.OpenPay.setId(process.env.VUE_APP_OPENPAY_ID);
        window.OpenPay.setApiKey(process.env.VUE_APP_OPENPAY_PUKEY);
        window.OpenPay.setSandboxMode(false);
        const deviceSessionId = window.OpenPay.deviceData.setup();
        payment.value.deviceSessionId = deviceSessionId;
    }

    const getChargeToken = (sendPayment = true) => {
      window.OpenPay.token.extractFormAndCreate('form_payment', (response) => {
        const token_id = response.data.id;
        payment.value.token_id = token_id;

        if (sendPayment) {
          processPayment();
        }

      }, (err) => {
        isLoading.value = false;

        Swal.fire({
          toast: true,
          position: "top-right",
          iconColor: "white",
          width: 300,
          text: err.data.description,
          customClass: {
            popup: "bg-warning",
            htmlContainer: "text-white",
          },
          showConfirmButton: false,
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        })

        return;
      });
    }

    onBeforeMount(() => {
      // store.dispatch('profile/getProfile').then(() => {
        const user =  store.getters['profile/profile'];
        payment.value.paymentEmail = user.email;
        payment.value.paymentName = user.name;
      // }).catch(e => console.err(e));

      getDeviceSessionId();
    });

    const processPayment = async () => {
      paymentError.value = false;
      if (document.getElementById('quotes_list')) {
        document.getElementById('quotes_list').classList.add('d-none')
      }

      disableSearcher.value = true;

      const response = await services.byuNow({shipper, recipient, payment});

      if (response.status == 500) {
        isLoading.value = false;
        paymentError.value = true;
        guide.value = {error: response.data.message};
        return;
      }

      if (response.message !== 'Success') {

        isLoading.value = false;

        Swal.fire({
          toast: true,
          position: "top-right",
          iconColor: "white",
          width: 300,
          text: response.data.message,
          customClass: {
            popup: "bg-warning",
            htmlContainer: "text-white",
          },
          showConfirmButton: false,
          showCloseButton: true,
          timer: 7000,
          timerProgressBar: true,
        })

        /* En este punto la entidad de pago ya registró este intento de pago (mediante el quote_id), se debe generar uno nuevo */
        const transactionAlreadyExistsMessage = "Te pedimos disculpas por las molestias. Parece que hubo un problema con la transacción. Por favor, vuelve a intentar realizar la compra. Si el problema persiste, no dudes en contactarnos. Estamos aquí para ayudarte a completar tu envío sin complicaciones."
        guide.value = {error: transactionAlreadyExistsMessage};
        return;
      }

      guide.value = response.data;

      isLoading.value = false;

    }

    const handleSubmitB2B = () => {
      isLoading.value = true;
      payment.value.paymentPaymentType = null;
      processPayment();
    }

    const handleSubmit = () => {
      isLoading.value = true;
      getChargeToken();
    }

    const isNumber = (evt, limit = 10) => {

      if (evt.target.value.length > limit - 1) {
        evt.preventDefault();
      }

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    const search = computed(() => store.state.dashboard.search)

    watch(
      () => search?.value?.quote?.require_package_declaration,
      (newValue) => {

        if (!newValue) {
          shipper.value.shipmentDescription = 'N/A';
          shipper.value.shipmentDeclaredValue = 1;
        } else {
          shipper.value.shipmentDescription = '';
          shipper.value.shipmentDeclaredValue = 0;
        }
      },
      { immediate: true }
    );

    return {
			search,
			user: computed(() => store.state.profile.profile),
      currency: (money) => new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(money),
      saved: (publicPrice, price) => {
        const n = Number(publicPrice.replace('$', '').replace(',', '')) - Number(price.replace('$', '').replace(',', ''));
        return `$${n.toFixed(2)}`
      },
			disableSearcher,
			isUser: store.getters['profile/isUser'],
      step,
      shipper,
      shipperSchema,
      recipient,
      recipientSchema,
      payment,
      paymentSchema,
      paymentError,
      isLoading,
      guide,
      avatar,

      handleSubmit,
      handleSubmitB2B,
      isNumber,
      reload: () => window.location.reload()
    };
  },
};
</script>

<style>
.searcher {
  background-color: #00B98E !important;
}
.form-label text-white {
  margin-bottom: 0.5rem !important;
  color: #FFF;
}
.second_text {
    height: 33px !important;
}

</style>
