import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/auth/';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "login", user);
    if (response.data.access_token) {
      localStorage.setItem('isVerified', response.data.user.email_verified_at)
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },

  async resendConfirmation() {
    await axios.post(API_URL + "resend-confirmation", {}, { headers: authHeader() });
  },

  async logout() {
    try {
      await axios.post(API_URL + "logout", {}, { headers: authHeader() });
    } finally {
      localStorage.clear();
    }
  },

  async register(user) {
    const response = await axios.post(API_URL + "register", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },
};
