import { useFetch } from "../Http";

const backend = (notification = '') => `${process.env.VUE_APP_API_BASE_URL}/dashboard/notifications${notification}`;

const getUnread = async() => {

    const { data } = await useFetch(backend());

    return data.value;
}

const read = async(notification) => {

    const { error } = await useFetch(backend(`/${notification}`), 'put');

    return error.value;
}

export { getUnread, read }