<script setup>
import { onMounted } from 'vue';
import { downloadBill, getPayments } from '../../services/Admin/billing';
import { ref } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  company: {
    type: String,
    required: true
  }
});

const billsList = ref([]);

onMounted(async () => {

  const {bills} = await getPayments(props.company)
  billsList.value = bills;
})

// eslint-disable-next-line no-unused-vars
const currency = (money) =>
  new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(money);

// eslint-disable-next-line no-unused-vars
function formatDate(fecha) {
    var date = fecha.split('-')

    var months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var mes = months[Number(date[1]) - 1];

    var fechaFormateada = mes + "/" + date[0];

    return fechaFormateada;
}

// eslint-disable-next-line no-unused-vars
const download = async (bill) => {
  await downloadBill(props.company, bill)
}

</script>
<template>
  <div>
    <ul class="list-group">
      <li v-for="bill in billsList" :key="bill.id" class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" >
        <div class="d-flex flex-column">
          <h6 class="mb-1 text-dark font-weight-bold text-sm">#{{ bill.id }} | <span class="font-weight-normal">
            {{ (formatDate(bill.from) == formatDate(bill.to)) ? formatDate(bill.from) : `${formatDate(bill.from)} - ${formatDate(bill.to)}` }}.
          </span></h6>

          <span class="text-xs">{{ bill.sended }} envíos realizados entre {{ bill.from }} - {{ bill.to }}</span>
          <span class="text-xs">Registrado el {{ bill.created_at }}</span>
        </div>
        <div class="d-flex align-items-center text-sm">
          {{ currency(bill.price) }}
          <button @click="download(bill.id)" class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
            <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i> PDF
          </button>
        </div>
      </li>
      <p class="text-center" v-if="!billsList.length">No hay pagos registrados</p>
    </ul>
  </div>
</template>
