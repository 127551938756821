<script setup>
import { onMounted, ref, watch } from "vue";
import Swal from "sweetalert2";
import { getHistory, getDetails, payNow } from "../services/Admin/billing";
import { useRouter } from "vue-router";

const router = useRouter();

const companies = ref(null);
const companiesDate = ref(null);

const filter    = ref({});
// eslint-disable-next-line no-unused-vars
const isLoading = ref(false);
const company   = ref(null);
const total   = ref(null);
const details   = ref(null);

// eslint-disable-next-line no-unused-vars
const currency = (money) =>
  new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(money);

onMounted(async () => await getInitHist());

const getInitHist = async () => {
  const {
    data: { value },
  } = await getHistory();
  companies.value = value.history;
  companiesDate.value = value.dates;
}

watch(company, async (newVal) => {

  if (newVal == null) {
    return;
  }
  await fetchData();

})

// eslint-disable-next-line no-unused-vars
const selectCompany = (selected) => {
  company.value = selected;
};

const changeDates = (dates) => {
  filter.value.from = new Date(dates.from).toLocaleDateString('en-CA');
  filter.value.to   = new Date(dates.to).toLocaleDateString('en-CA');
}

// const resetDate = () => {
//   filter.value.from = new Date(companiesDate.value.from).toLocaleDateString('en-CA');
//   filter.value.to = new Date(companiesDate.value.to).toLocaleDateString('en-CA');
// };

// eslint-disable-next-line no-unused-vars
const fetchData = async () => {
  isLoading.value = true;
  try {
    const {data: {value}} = await getDetails(company.value.company_id, filter.value);

    details.value = value.history;
    total.value = value.total;
    changeDates(value.dates);
  } catch (error) {
    console.log(error);
    // resetDate();
  }
  finally {
    isLoading.value = false;
  }
}

// eslint-disable-next-line no-unused-vars
const payHandler = () => {

  const swalAlert = Swal.mixin({
    customClass: {
      confirmButton: "btn bg-gradient-success m-1",
      cancelButton: "btn bg-gradient-danger m-1"
    },
    buttonsStyling: false
  });

  swalAlert.fire({
    title: `¿Marcar como pagado?`,
    text: `${company.value.name} ya ha pagado?`,
    footer: `${details.value.length} envíos serán marcados como pagados.`,
    showCancelButton: true,
    confirmButtonText: `Ya ha pagado ${currency(total.value)}`,
    cancelButtonText: "No, cancelar!",
  }).then((result) => {
    (result.isConfirmed) ? pay() : null;
  });
}

const pay = () => {
  isLoading.value = true;
  payNow(company.value.company_id, filter.value).then(({data, error}) => {

    if (error.value != null) {
      isLoading.value = false;
      return Swal.fire({
        title: "El pago no se ha podido realizar!",
        text: error.value.message,
        icon: "error"
      });
    }

    const { bill } = data.value;

    filter.value    = {};
    company.value   = null;
    total.value     = null;
    details.value   = null;
    isLoading.value = false;
    getInitHist();

    return Swal.fire({
      title: "Pago registrado!",
      text: `Pago # ${bill.id} por ${currency(bill.price)}`,
      icon: "success"
    });
  })
}

// eslint-disable-next-line no-unused-vars
const goToCompany = (company) => {
  router.push({ name: 'Company', params: {id: company}, hash: '#bills' })
}

// eslint-disable-next-line no-unused-vars
const indicator = ({sended, pending}) => {
  let bg = 'success';

  if (sended == 0 && pending == 0) {
    bg = 'dark';
  } else if(pending != 0) {
    bg = 'danger';
  }

  return bg;
}

</script>

<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <!-- Sidebar -->
      <div class="col-md-4">
        <div class="mt-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Empresas</h6>
            <p v-if="companiesDate" class="text-xs mt-2 mb-0">
              Del {{ companiesDate.from }} al {{ companiesDate.to }}
            </p>
          </div>
          <div class="p-3 card-body">
            <div v-if="!companies" class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <ul v-else class="list-group">
              <li
                v-for="company in companies"
                :key="company.company_id"
                class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg ps-0"
              >
                <div role="button" @click="goToCompany(company.company_id)" class="d-flex align-items-center">
                  <div :class="`text-center shadow icon icon-shape icon-sm me-3 bg-gradient-${indicator(company)}`">
                    <svg class="p-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="color: white">
                      <path class="" fill="currentColor" d="M234.5 5.709C248.4 .7377 263.6 .7377 277.5 5.709L469.5 74.28C494.1 83.38 512 107.5 512 134.6V377.4C512 404.5 494.1 428.6 469.5 437.7L277.5 506.3C263.6 511.3 248.4 511.3 234.5 506.3L42.47 437.7C17 428.6 0 404.5 0 377.4V134.6C0 107.5 17 83.38 42.47 74.28L234.5 5.709zM256 65.98L82.34 128L256 190L429.7 128L256 65.98zM288 434.6L448 377.4V189.4L288 246.6V434.6z"></path>
                    </svg>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">{{ company.name }}</h6>
                    <span class="text-xs"
                      >Envíos: {{ company.sended }} -
                      <strong>Facturado: {{ currency(company.billed) }}</strong></span
                    >
                    <strong class="text-xs text-danger m-0" v-if="company.pending">Envíos pendientes: {{ company.pending }}</strong>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    @click="selectCompany(company)"
                    class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
                  >
                    <i class="ni ni-bold-right" aria-hidden="true"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Details -->
      <div v-if="company" class="col-md-8">
        <div class="mt-4 card">
          <div v-if="!details" class="text-center my-5">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div class="p-3 pb-0 card-header">
              <div class="d-flex align-items-center">
                <h6>Detalles - {{ company.name }} - {{ currency(total) }}</h6>
                <button
                  @click="payHandler"
                  :disabled="isLoading"
                  type="button"
                  class="mb-0 btn btn-rounded btn-outline-success ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
                  title="Marcar como pagado"
                >
                  <i class="fas fa-check" aria-hidden="true"></i> &nbsp; Pagar
                </button>
              </div>

              <div class="d-flex justify-content-around my-2">
                <div>
                  <label for="date-from">Desde</label>
                  <input class="form-control form-control-sm" type="date" id="date-from" v-model="filter.from" :max="new Date().toLocaleDateString('en-CA')" :disabled="isLoading">
                </div>
                <div>
                  <label for="date-to">Hasta</label>
                  <input class="form-control form-control-sm" type="date" id="date-to" v-model="filter.to" :max="new Date().toLocaleDateString('en-CA')" :disabled="isLoading">
                </div>
                <div class="align-self-center">
                  <button @click="fetchData" class="btn btn-sm bg-gradient-dark m-0" :disabled="isLoading">Consultar</button>
                </div>
              </div>

            </div>
            <div class="px-3 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <table class="table mb-0 align-items-center justify-content-center">
                  <thead>
                    <tr>
                      <th class="text-center text-secondary text-xxs font-weight-bolder opacity-7 m-0 p-0">
                        #
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Creador
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Fecha
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Precio
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Paquete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="{
                          orden
                          , usuario
                          , paquete
                          , paqueteria
                          , tipo_servicio
                          , precio
                          , tiempo_viaje
                          , origen
                          , destino
                          , fecha
                        }, key in details" :key="orden">
                      <td>
                        <p class="mb-0 text-xs">{{ key + 1 }}</p>
                      </td>
                      <td>
                        <p class="mb-0 text-xs">{{ usuario.split(' - ')[0] }}</p>
                        <p class="mb-0 text-xs">{{ usuario.split(' - ')[1] }}</p>
                      </td>
                      <td>
                        <p class="mb-0 text-xs">{{ fecha }}</p>
                      </td>
                      <td>
                        <p class="mb-0 text-xs">{{ currency(precio) }}</p>
                      </td>
                      <td>
                        <table class="text-xs">
                          <tr>
                            <td class="no-space" colspan="2">
                              <div class="d-flex justify-content-between">
                                <span><span class="text-xs fw-bold">Paquetería</span> {{ paqueteria }}</span>
                                <span><span class="text-xs fw-bold">Tiempo en viaje</span> {{ tiempo_viaje }}</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="no-space fw-bold">Servicio</td>
                            <td class="no-space">{{ tipo_servicio }}</td>
                          </tr>
                          <tr>
                            <td class="no-space fw-bold">Origen</td>
                            <td class="no-space">{{ origen }}</td>
                          </tr>
                          <tr>
                            <td class="no-space fw-bold">Destino</td>
                            <td class="no-space">{{ destino }}</td>
                          </tr>
                          <tr>
                            <td class="no-space" colspan="2"><span class="fw-bold">Paquete</span> {{ paquete }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr v-if="!details.length">
                      <td colspan="5" class="text-center">Sin resultados</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  padding: 8px !important;
}

.no-space {
  padding: 3px !important;
}

</style>
