<script setup>
/* eslint-disable no-unused-vars */
import showSwal from "@/mixins/showSwal.js";
import { getNotPurchased } from "../services/Admin";
import { onMounted } from "vue";
import { ref } from "vue";

const fromDate = new Date();
fromDate.setDate(1);
const maxDate = new Date().toLocaleDateString('en-CA')

const isLoading = ref(true);
const filter = ref({
  user: -1,
  from: fromDate.toLocaleDateString('en-CA'),
  to: new Date().toLocaleDateString('en-CA')
});
const searches = ref(null);

onMounted(async () => {
  await fetchData();
})

const fetchData = async () => {
  const {data:{value}, error:{value:err}} = await getNotPurchased(filter.value);
  searches.value = value;
  isLoading.value = false;
  // console.log(err);

  if (err) {
    showSwal.methods.showSwal({
      type: "error",
      width: "auto",
      message: err.message,
      timer: 5500
    });
  }

}

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="form-group"></div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between">
              <div>
                <h5 class="mb-0">Búsquedas no adquiridas</h5>
                <span>Total: {{ searches?.total ?? 0 }} </span>
              </div>
              <div class="row">
                <div class="col-6">
                  <label for="date-from">Desde</label>
                  <input @change="fetchData" class="form-control w-auto" type="date" id="date-from" v-model="filter.from" :max="maxDate" :disabled="isLoading">
                </div>
                <div class="col-6">
                  <label for="date-to">Hasta</label>
                  <input @change="fetchData" class="form-control w-auto" type="date" id="date-to" v-model="filter.to" :max="maxDate" :disabled="isLoading">
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table v-if="searches" id="searches-list" ref="searchesList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Usuario</th>
                    <th>Búsqueda</th>
                    <th>Cotizaciones</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-for="search in searches.searches" :key="search.search_id">
                    <td>
                      <p class="text-sm m-0 p-0">{{ search.user.name }} - <span class="text-xs">{{ search.user.role_name }}</span></p>
                      <p class="text-sm m-0 p-0">{{ search.user.email }}</p>
                    </td>
                    <td>
                      <table class="table text-sm">
                        <tr>
                          <td class="p-0">Origen: {{ search.origin }}</td>
                          <td class="p-0">Tipo: {{ search.type }}</td>
                        </tr>
                        <tr>
                          <td class="p-0">Alto: {{ search.high }} cm</td>
                          <td class="p-0">Ancho: {{ search.width }} cm</td>
                        </tr>
                        <tr>
                          <td class="p-0">Largo: {{ search.long }} cm</td>
                          <td class="p-0">Peso: {{ search.mass }} kg</td>
                        </tr>
                        <tr>
                          <td class="p-0" colspan="2">
                            <p class="text-xs m-0 px-0 pb-0 fw-bolder">Fecha: {{ search.created_at }}</p>
                          </td>
                        </tr>
                      </table>

                    </td>
                    <td>
                      <p class="text-sm m-0">Origen: {{ search.from }} </p>
                      <p class="text-sm m-0">Destino {{ search.to }} </p>
                      <table class="table mt-3 text-sm w-100">
                        <tr class="w-100">
                          <th class="w-15">Paquetería</th>
                          <th class="w-30">Servicio</th>
                          <th class="w-35">Tiempo</th>
                          <th class="w-20">Precio</th>
                        </tr>
                        <tr v-if="!search.quotes.length">
                          <td colspan="4" class="text-center fw-bolder">Sin cotizaciones para esta búsqueda</td>
                        </tr>
                        <tr v-for="(quote, key) in search.quotes" :key="key">
                          <td class="p-1">{{ quote.provider }}</td>
                          <td class="p-1">{{ quote.shipping_type }}</td>
                          <td class="p-1">{{ quote.delivery_date }}</td>
                          <td class="p-1">${{ quote.price }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <p class="text-center"> Cargando ...</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
td {
  padding: 12px 24px !important;
}
</style>
