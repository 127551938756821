<template>
  <div class="py-4 container-fluid" v-if="profile">

    <AccountDeactivated class="mb-5" v-if="profile?.company && profile.company.deleted_at !== null" />

    <div v-if="isLoading" class="d-flex justify-content-center mb-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-if="stats.shipments.currentDay != null">
        <mini-statistics-card
          title="Envíos del día"
          :value="stats.shipments.currentDay"
          :icon="{
            component: 'ni ni-cart',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-if="stats.shipments.currentDayAmount">
        <mini-statistics-card
          title="Monto del día"
          :value="stats.shipments.currentDayAmount"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-if="stats.newClientsOnThisMonth">
        <mini-statistics-card
          title="Nuevos clientes"
          :value="stats.newClientsOnThisMonth ?? 0"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0" v-if="stats.shipments.INTLCurrentDay != null">
        <mini-statistics-card
          :title="`INTL ${stats.shipments.INTLCurrentDay ?? 0} envíos`"
          :value="stats.shipments.INTLCurrentDayAmount.replace('.00', '')"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <template v-if="stats?.shippers?.length">
        <div v-for="shipper in stats.shippers" :key="shipper.name" class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
            :title="shipper.name"
            :value="shipper.total.replace('.00', '')"
            :icon="{
              component: 'ni ni-delivery-fast',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
      </template>

      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4" v-if="stats.currentDayTotal">
        <mini-statistics-card
          title="Total del mes"
          :value="stats.currentDayTotal.replace('.00', '')"
          :icon="{
            component: 'ni ni-cart',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

    </div>

    <div class="row my-4">
      <div class="col-md-12 mb-md-0 mb-4">
        <projects-card />
      </div>
    </div>

    <div class="row my-4">
      <div class="card z-index-2">

        <div v-if="isLoading" class="d-flex justify-content-center mb-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <gradient-line-chart
          v-else
          id="chart-line"
          title="Envíos - Monto"
          description="Envíos del mes (General)"
          :chart="{
            labels: labels,
            datasets: datasets,
          }"
        />
      </div>
    </div>

  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import ProjectsCard from "./components/ProjectsCard.vue";
import AccountDeactivated from "../components/AccountDeactivated.vue";

import service from "@/services/Shipments/index.service.js"

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      isLoading: true,
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      stats: null,
      datasets: [],
      labels: [],
    };
  },
  async beforeCreate() {

    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    urlSearchParams.has('noAdmin');

    if (urlSearchParams.has('noAdmin')) {

      /* Refresca el estado para que tome el nuevo role */
      const currentPathWithoutQuery = window.location.pathname;
      window.location = currentPathWithoutQuery;
    }

    const { data } = await service.getCardsStats();
    this.stats = data;
    this.hidrateGraph()
    this.isLoading = false;
  },

  components: {
    MiniStatisticsCard,
    GradientLineChart,
    ProjectsCard,
    AccountDeactivated
  },

  methods : {
    hidrateGraph() {
      const total = [];
      const send = [];
      const labels = []

      this.stats.graph.map(item => {
        total.push(item.total)
        send.push(item.send)
        labels.push(item.month)
      });

      this.labels = labels;

      this.datasets = [
        {
          label: 'Enviado',
          data: send,
        },
        {
          label: 'Total',
          data: total,
        }
      ]
    }
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    }
  },

};
</script>
