<template>
  <div class="container-fluid mt-4">
    <div id="profile" class="card card-body mt-4">
      <div v-if="profile" class="row justify-content-center align-items-center">
        <div class="col-sm-auto col-4">
          <soft-avatar
            :img="pic"
            alt="profile"
            size="xl"
            shadow="sm"
            border-radius="lg"
          />
        </div>
        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">{{ profile.name }}</h5>
            <p class="mb-0 font-weight-bold text-sm">{{ profile.email }}</p>
          </div>
        </div>

        <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
          <!-- <label class="form-check-label mb-0 me-2">
            <small id="profileVisibility">Switch to invisible</small>
          </label>
          <soft-switch id="profile" name="profileVisibility" checked /> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div id="basic-info" class="card mt-4">
          <div class="card-header">
            <h5>Perfil</h5>
          </div>

          <div class="card-body pt-0">
            <div class="row mb-3">
              <label class="form-label">Nombre</label>
              <soft-model-input
                id="name"
                v-model="profileChange.name"
                type="text"
                placeholder="Nombre"
              />
              <validation-error :errors="apiValidationErrors.name" />
            </div>

            <div class="row mb-3">
              <label class="form-label mt-2">Correo</label>
              <soft-model-input
                id="email"
                v-model="profileChange.email"
                type="email"
                placeholder="example@email.com"
              />
              <validation-error :errors="apiValidationErrors.email" />
            </div>

            <soft-button
              class="float-end mt-6 mb-0"
              color="dark"
              variant="gradient"
              size="sm"
              :is-disabled="loading2 ? true : false"
              @click="handleProfileChange"
              ><span
                v-if="loading2"
                class="spinner-border spinner-border-sm"
              ></span
              ><span v-else>Actualizar perfil</span></soft-button
            >
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div id="password" class="card mt-4">
          <div class="card-header">
            <h5>Cambiar contraseña</h5>
          </div>
          <Form
            role="form"
            class="px-3"
            :validation-schema="schema"
            @submit="handlePassChange"
          >
            <div class="card-body pt-0">
              <div class="row mb-3">
                <label class="form-label px-0">Actual contraseña</label>
                <Field
                  autocomplete="off"
                  name="current_password"
                  type="password"
                  placeholder="Actual contraseña"
                  class="form-group mb-0 form-control"
                  v-model="passChange.current_password"
                />
                <ErrorMessage
                  name="current_password"
                  class="error-feedback text-xs text-danger"
                  role="alert"
                />
              </div>

              <div class="row mb-3">
                <label class="form-label px-0">Nueva contraseña</label>
                <Field
                  autocomplete="off"
                  type="password"
                  name="password"
                  placeholder="Actual contraseña"
                  class="form-group mb-0 form-control"
                  v-model="passChange.password"
                />
                <ErrorMessage
                  name="password"
                  class="error-feedback text-xs text-danger"
                  role="alert"
                />
              </div>

              <div class="row mb-3">
                <label class="form-label px-0">Confirma tu contraseña</label>
                <Field
                  autocomplete="off"
                  v-model="passChange.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  placeholder="Actual contraseña"
                  class="form-group mb-0 form-control"
                />
                <ErrorMessage
                  name="password_confirmation"
                  class="error-feedback text-xs text-danger"
                  role="alert"
                />
              </div>

              <soft-button
                type="submit"
                color="dark"
                variant="gradient"
                class="float-end mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                ><span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span v-else>Actualizar contraseña</span></soft-button
              >

              <p class="text-xs">Será enviado de nuevo al inicio de sesión *</p>
            </div>
          </Form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftModelInput from "@/components/SoftModelInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import showSwal from "/src/mixins/showSwal.js";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Profile",
  components: {
    SoftButton,
    SoftAvatar,
    SoftModelInput,
    ValidationError,
    Form,
    ErrorMessage,
    Field
  },
  mixins: [formMixin],
  data() {
    const schema = yup.object().shape({
      current_password: yup
        .string()
        .required("La contraseña actual es requerida!")
        .min(8, "La contraseña debe contener al menos 8 caracteres!"),
      password: yup
        .string()
        .required("La nueva contraseña es requerida!")
        .min(8, "La contraseña debe contener al menos 8 caracteres!"),
      password_confirmation: yup
        .string()
        .test("passwords-match", "Las contraseñas no coinciden!", function (value) {
          return this.parent.password === value;
        }),
    });
    return {
      schema,
      passChange: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      profileChange: {
        name: "",
        email: "",
        profile_image: "",
      },
      loading: false,
      loading2: false,
      img,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    pic() {
      if (this.profile.profile_image) return this.profile.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async created() {
    try {
      // await this.$store.dispatch("profile/getProfile");
      this.profileChange.name = this.profile.name;
      this.profileChange.email = this.profile.email;
    } catch (error) {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    async handlePassChange() {

      this.loading = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.passChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Éxito",
          width: 500,
        });
        this.passChange.password = "";
        this.passChange.password_confirmation = "";
        this.loading = false;

        await this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.message,
          width: 500,
        });
        this.loading = false;
      }
    },

    async handleProfileChange() {

      this.loading2 = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.profileChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Éxito",
          width: 600,
        });
        this.loading2 = false;
        this.$router.go()
      } catch (error) {
        console.log(error);
        this.loading2 = false;
      }
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}
</style>
