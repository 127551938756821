<template>

  <!-- on open #sidenav-main remove z-index -->
  <div>

    <div class="modal fade" id="shipmentDetailsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="3" aria-labelledby="shipmentDetailsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" style="box-shadow: none;">
        <div v-if="shipmentSelected" class="modal-content">
          <div class="modal-header">

            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-shipment-tab" data-bs-toggle="tab" data-bs-target="#nav-shipment" type="button" role="tab" aria-controls="nav-shipment" aria-selected="true">Envío</button>
                <button class="nav-link" id="nav-shipper-tab" data-bs-toggle="tab" data-bs-target="#nav-shipper" type="button" role="tab" aria-controls="nav-shipper" aria-selected="false">Origen</button>
                <button class="nav-link" id="nav-recipient-tab" data-bs-toggle="tab" data-bs-target="#nav-recipient" type="button" role="tab" aria-controls="nav-recipient" aria-selected="false">Destino</button>
              </div>
            </nav>

            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg mb-1" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
              </svg>
            </button>
            <div class="p-2"></div>
          </div>
          <div class="modal-body">

            <div class="card shadow">
              <div class="tab-content p-3" id="nav-tabContent">

                <!-- Envío -->
                <div class="tab-pane fade active show" id="nav-shipment" role="tabpanel" aria-labelledby="nav-shipment-tab">

                  <div class="row text-sm mb-0">
                    <div class="col-8">No. de orden <strong>{{ shipmentSelected.shipment_id }}</strong></div>
                    <div class="col-4">Costo <strong>{{ formatCurrency(shipmentSelected.quote.price) }}</strong></div>
                    <table class="mt-3" style="width: 100%;">
                      <tr>
                        <td class="py-0 my-0">Fecha de envío</td>
                        <td class="py-0 my-0">Tiempo de envío</td>
                        <td class="py-0 my-0">No. de rastreo</td>
                      </tr>
                      <tr>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.created_at }}
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.delivery_date }}
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.tracking_number }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <p class="m-0">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            Rastreo
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0 my-0" colspan="3">
                          <div class="text-sm m-0 d-flex justify-content-around" >

                            <div v-if="!shipmentSelected.status?.shipmentPath?.length" >Sin información</div>
                            <div v-else >
                              <strong>Actualmente en:</strong> {{ shipmentSelected.status.currentLocation }}
                              <a href="#" class="mx-3" @click="showPath = !showPath"> {{ showPath ? 'Ocultar' : 'Mostrar' }} </a>
                            </div>

                            <button
                              class="btn btn-sm btn-dark m-0"
                              type="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Actualizar estatus"
                              @click="refreshStatus(shipmentSelected.shipment_id)"
                              :disabled="status.isLoading"
                            >
                              <svg v-if="!status.isLoading" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                              </svg>
                              <div v-else class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              &nbsp; {{ status.isLoading ? '' : 'Consultar' }}
                            </button>
                          </div>

                          <div v-if="shipmentSelected.status?.shipmentPath?.length && showPath">
                            <div class="card-body d-flex justify-content-center" v-if="! status.isLoading" >
                                <div class="timeline timeline-one-side" data-timeline-axis-style="dotted">
                                  <div class="mb-3 timeline-block" v-for="(path, index) in shipmentSelected.status.shipmentPath" :key="index">
                                    <span class="timeline-step">
                                      <i :class="`ni text-gradient ${index == 0 ? 'ni-bell-55' : 'ni-archive-2' } text-success`"></i>
                                    </span>
                                    <div class="timeline-content">
                                      <h6 class="mb-0 text-sm font-weight-bold text-dark"> {{ path.location }}</h6>
                                      <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">{{ path.date }}</p>
                                      <span class="badge badge-sm me-1 bg-gradient-success">{{ path.eventDescription.split(' : ')[0] }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <hr>
                  <div class="row text-sm mb-0">
                    <p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-box-seam-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003zM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461z"/>
                      </svg>
                      &nbsp; Paquete
                    </p>

                    <table class="mb-3" style="width: 100%;">
                      <tr>
                        <td class="py-0 my-0">Alto</td>
                        <td class="py-0 my-0">Ancho</td>
                        <td class="py-0 my-0">Largo</td>
                        <td class="py-0 my-0">Peso</td>
                      </tr>
                      <tr>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.high }} CM
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.width }} CM
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.long }} CM
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.mass }} KG
                        </td>
                      </tr>
                    </table>

                    <p class="text-sm mb-0">Valor: {{ formatCurrency(shipmentSelected.shipper.package_declared_value) }}</p>
                    <p class="text-sm mb-0">Descripción: {{ shipmentSelected.shipper.package_description }}</p>
                    <p class="text-sm mb-0">Enviado por: {{ shipmentSelected.quote.shipping_provider.name }} </p>

                  </div>
                  <hr>
                  <div class="row text-sm mb-0" v-if="shipmentSelected.quote.search.user">
                    <p>
                      <i class="fa fa-user" aria-hidden="true"></i>
                      &nbsp; {{ shipmentSelected.quote.search.origin === 'ldg' ? 'Usuario de Landing' : 'Colaborador'}}
                    </p>

                    <table class="mb-3" style="width: 100%;">
                      <tr>
                        <td class="py-0 my-0">Nombre</td>
                        <td class="py-0 my-0">Email</td>
                      </tr>
                      <tr>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.user.name }}
                        </td>
                        <td class="py-0 my-0">
                          {{ shipmentSelected.quote.search.user.email }}
                        </td>
                      </tr>
                    </table>

                  </div>

                </div>

                <!-- Origen -->
                <div class="tab-pane fade" id="nav-shipper" role="tabpanel" aria-labelledby="nav-shipper-tab">
                  <!-- <h4 class="text-primary">Datos de Origen</h4> -->
                  <p class="fw-bold">
                      <span id="shipper_address">
                        {{ getLocation(shipmentSelected.quote.search.locationsRelates.shipper) }}
                      </span>
                  </p>

                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="shipperName" class="form-label">Nombre de contacto</label>
                          <input type="text" class="form-control" name="shipperName" id="shipperName" v-model="shipmentSelected.shipper.name" disabled>
                      </div>
                      <div class="col-md-6 col-12">
                          <label for="shipperPhone" class="form-label">Teléfono de contacto</label>
                          <input type="text" class="form-control" name="shipperPhone" id="shipperPhone" v-model="shipmentSelected.shipper.phone" disabled>
                      </div>
                  </div>
                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="shipperAddress" class="form-label">Dirección (calle)</label>
                          <input type="text" class="form-control" name="shipperAddress" id="shipperAddress" v-model="shipmentSelected.shipper.address" disabled>
                      </div>
                      <div class="col-md-6 col-12">
                          <label for="shipperAddressNumber" class="form-label">Dirección (número)</label>
                          <input type="text" class="form-control" name="shipperAddressNumber" id="shipperAddressNumber" v-model="shipmentSelected.shipper.number" disabled>
                      </div>
                  </div>
                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="shipperReference" class="form-label">Referencia</label>
                          <textarea class="form-control" name="shipperReference" id="shipperReference" rows="" cols="" :value="shipmentSelected.shipper.reference" disabled></textarea>
                      </div>
                      <div class="col-md-6 col-12">
                          <label for="shipperCompany" class="form-label">Empresa (Opcional)</label>
                          <input type="text" class="form-control" name="shipperCompany" id="shipperCompany" placeholder="Empresa" v-model="shipmentSelected.shipper.company" disabled>
                      </div>
                  </div>
                </div>

                <!-- Destino -->
                <div class="tab-pane fade" id="nav-recipient" role="tabpanel" aria-labelledby="nav-recipient-tab">
                  <!-- <h4 class="text-primary">Datos de Destino</h4> -->
                  <p class="fw-bold">
                      <span id="recipient_address">
                        {{ getLocation(shipmentSelected.quote.search.locationsRelates.recipient) }}
                      </span>
                  </p>

                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="recipientName" class="form-label">Nombre de contacto</label>
                          <input type="text" class="form-control" name="recipientName" id="recipientName" v-model="shipmentSelected.recipient.name" disabled>
                      </div>
                      <div class="col-md-6 col-12">
                          <label for="recipientPhone" class="form-label">Teléfono de contacto</label>
                          <input type="text" class="form-control" name="recipientPhone" id="recipientPhone" v-model="shipmentSelected.recipient.phone" disabled>
                      </div>
                  </div>
                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="recipientAddress" class="form-label">Dirección (calle)</label>
                          <input type="text" class="form-control" name="recipientAddress" id="recipientAddress" v-model="shipmentSelected.recipient.address" disabled>
                      </div>
                      <div class="col-md-6 col-12">
                          <label for="recipientAddressNumber" class="form-label">Dirección (número)</label>
                          <input type="text" class="form-control" name="recipientAddressNumber" id="recipientAddressNumber" v-model="shipmentSelected.recipient.number" disabled>
                      </div>
                  </div>
                  <div class="row g-3 mb-3">
                      <div class="col-md-6 col-12">
                          <label for="recipientReference" class="form-label">Referencia (Opcional)</label>
                          <textarea class="form-control" name="recipientReference" id="recipientReference" rows="" cols="" :value="shipmentSelected.recipient.reference" disabled></textarea>

                      </div>
                      <div class="col-md-6 col-12">
                          <label for="recipientCompany" class="form-label">Empresa</label>
                          <input type="text" class="form-control" name="recipientCompany" id="recipientCompany" v-model="shipmentSelected.recipient.company" disabled>
                      </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-warning" @click="printDoc(shipmentSelected.link)">
              <i class="fa fa-print" aria-hidden="true"></i> &nbsp; Imprimir
            </button>
            <button @click="download(shipmentSelected.link)" class="btn bg-gradient-success btn-block" type="button">
              <i class="fa fa-cloud-download" aria-hidden="true"></i> &nbsp; Descargar
            </button>
            <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="card" v-if="profile">

    <div class="card-header pb-0">
      <div class="row" v-if="shipments">
        <div class="col-lg-4 col-7">
          <h6>Envíos realizados</h6>
          <p class="text-sm m-0 p-0">Total: {{ shipments.total }} - Página {{ shipments.current_page }} de {{ shipments.last_page }}, mostrando {{ shipments.per_page }} por página. </p>
        </div>
      </div>
      <!-- Filters -->
      <div class="row justify-content-around mt-3">
        <div class="col-md-3" v-if="companies?.length">
          <label for="users">Compañía</label>
          <select @change="getCompanies" class="form-control w-100" id="users" v-model="filter.company" :disabled="isLoading">
            <option v-for="company in companies"
              :key="company.company_id"
              :value="company.company_id"
            > {{ company.name }}</option>

          </select>
        </div>

        <div class="col-md-5" v-if="collaborators?.length || collaboratorsIsEmpty">
          <label for="users">Colaboradores</label>
          <select @change="fetch" class="form-control w-100" id="users" v-model="filter.user" :disabled="collaboratorsIsEmpty || isLoading">
            <option value="-1" v-if="! collaboratorsIsEmpty">Todos</option>
            <option value="-1" v-else>Sin colaboradores</option>
            <option v-for="user in collaborators" :key="user.id" :value="user.id"> {{ user.email }} - {{ user.name }}</option>
          </select>
        </div>

        <div class="col-md-1">
          <label for="shipping_providers">Paqueterías</label>
          <select @change="fetch" class="form-control w-auto" id="shipping_providers" v-model="filter.provider" :disabled="isLoading">
            <option value="-1">Todos</option>
            <option value="1">FedEx</option>
            <option value="2">DHL</option>
            <option value="3">Estafeta</option>
            <option value="4">UPS</option>
          </select>
        </div>

        <div class="col-md-3 d-flex">
          <div>
            <label for="date-from">Desde</label>
            <input @change="fetch" class="form-control w-auto" type="date" id="date-from" v-model="filter.from" :disabled="isLoading">
          </div>
          <div>
            <label for="date-to">Hasta</label>
            <input @change="fetch" class="form-control w-auto" type="date" id="date-to" v-model="filter.to" :max="max" :disabled="isLoading">
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div v-if="isLoading" class="d-flex justify-content-center mb-3">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Compañía
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" style="max-width:30%">
                Tipo de envío
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Costo
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Tiempo de envío
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Fecha
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Fecha
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shipment, index of shipments.data" :key="shipment.shipment_id" >
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar :img="photo(shipment.quote.shipping_provider.photo)" size="sm" class="me-3" :alt="shipment.quote.shipping_provider.name" />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ shipment.quote.shipping_provider.name }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-sm">
                <span class="text-xs font-weight-bold">{{ shipment.quote.shipping_type }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ formatCurrency(shipment.quote.price) }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ shipment.quote.delivery_date }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ shipment.quote.created_at }}</span>
              </td>
              <td class="align-middle text-center">
                <button @click="showModalFn(index)" type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#shipmentDetailsModal">
                  Detalles
                </button>
              </td>
            </tr>
            <tr v-if="!shipments.data.length">
              <td colspan="6" class="text-center p-5">Sin registros ... </td>
            </tr>
          </tbody>
          <tfoot v-show="shipments.data.length">
            <tr>
              <td colspan="6">
                  <p class="text-center p-0 m-0">
                    <button v-for="(link, key) in shipments.links" :key="`pagination_${key}`"
                      class="btn btn-sm w-auto mx-1 m-2"
                      :disabled="!link.url"
                      @click="toPage(link.url)"
                      :class="link.active ? 'bg-gradient-dark' : 'bg-gradient-secondary' "
                      v-html="link.label"
                    ></button>
                  </p>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td class="text-center mt-2">
                <button @click="exportCSV"
                  class="btn bg-gradient-success mt-3"
                  :disabled="status.isLoading"
                >
                  Exportar a CSV
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div v-else>Cargando ...</div>

</template>

<script>
import services from "@/services/Shipments/index.service.js";

import setTooltip from "@/assets/js/tooltip.js";
import SoftAvatar from "@/components/SoftAvatar.vue";
// import showSwal from "/src/mixins/showSwal.js";

export default {
  name: "projects-card",
  data() {
    return {
      companies: [],
      collaborators: [],
      collaboratorsIsEmpty: false,
      shipmentSelected: null,
      showModal: false,
      status: {
        isLoading: false,
        content: null
      },

      /* new */
      isLoading: true,
      isOnLoad: true,
      max: new Date().toLocaleDateString('en-CA'),
      shipments: null,
      showPath: true,
      filter: {
        page: 1,
        company: -1,
        user: -1,
        provider: -1,
        from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA'),
        to: new Date().toLocaleDateString('en-CA'),
      }
    };
  },
  components: {SoftAvatar},
  mounted() {
    setTooltip();
  },
  async created() {
    // await this.$store.dispatch("profile/getProfile")
    await this.getCompanies();
    this.isOnLoad = false;
  },
  methods: {
    photo(url) {
      return `${process.env.VUE_APP_LANDING_URL}${url}`
    },

    getQuery(query = {}) {
      return '?' + new URLSearchParams(query).toString()
    },

    async fetch() {
      const {data} = await services.getShipments(
        this.getQuery(this.filter)
      );

      this.shipments = data;
      this.isLoading = false;
    },

    async toPage(page) {
      page = page.split('page=')[1]

      if (this.shipments.current_page != page) {
        this.isLoading = true;
        this.filter.page = page;
        this.fetch();
      }

    },

    async getCompanies() {
      this.isLoading = true;

      try {

        if (this.profile.roles == 1) {
          const data = await services.companies()
          this.companies = data;
          if (this.isOnLoad) {
            this.filter.company = data[0].company_id;
          }
        }

        await this.getUsers();

      } catch (error) {
        console.log(error);
      }

      await this.fetch();

    },

    async getUsers() {
      const auth = this.profile;

      if (auth.roles > 2) { return; }

      if (auth.roles == 2) {
        this.filter.company = auth.company.company_id;
      }

      const collaborators =  await services.getUsers(this.filter.company);
      this.collaboratorsIsEmpty = collaborators.data.length == 0
      this.collaborators = collaborators.data

      this.filter.user = -1;

    },

    formatCurrency(amount) {
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
    },

    showModalFn(index) {
      this.shipmentSelected = this.shipments.data[index];
      this.shipmentSelected.status = JSON.parse(this.shipmentSelected.status);
    },

    async refreshStatus(shipment) {
      this.status.isLoading = !this.status.isLoading

      const data = await services.getShipmentStatus(shipment);

      if (data !== undefined) {
        this.shipmentSelected.status = data.data.status;
      }

      this.status.isLoading = !this.status.isLoading
      this.showPath = true;
    },

    getLocation(location) {
      return `${location.colonia}, ${location.municipio}, ${location.estado}, C.P. ${location.cp}`
    },

    download(url) {
      services.downloadDocument(url)
    },

    printDoc(url) {
      services.print(url)
    },

    exportCSV() {
      this.status.isLoading = true;
      services.export(
        this.filter, this.getQuery
      ).then(() => {
        this.status.isLoading = false;
      });
    },

    parseDate(dateString) {
      const date = new Date(dateString);
      const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      let horas = date.getHours();
      const min = ('0' + date.getMinutes()).slice(-2);
      const amPm = horas >= 12 ? 'PM' : 'AM';
      horas = horas % 12 || 12;

      return `${day} de ${month} ${year}, ${horas}:${min} ${amPm}`;
    }
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    }
  },
};
</script>
