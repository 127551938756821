<template>
<div>
    <div class="py-4 container-fluid">
    <div class="row my-4">
      <div class="card z-index-2">
        <div class="p-3 pb-0 card-header">
          <h6 v-html="title"></h6>
          <p class="text-sm" v-html="description"></p>
        </div>
        <div class="p-3 card-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "CardContainer",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    }
  },
};
</script>