<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return {
      search: computed(() => store.state.dashboard.search),
    };
  },
};
</script>

<template>
    <div class="card border" v-if="search.quote">
      <strong class="my-3 mx-auto">
        {{ search.quote.shipping_provider.name }} -
        {{ search.quote.shipping_type }}
        <span class="text-xs text-secondary">({{ search.quote.delivery_date }})</span>
      </strong>
      <table class="bg-dark text-white p-3 w-100">
        <tr>
          <td class="p-2">De:</td>
          <td class="p-2">
            {{ search.locationsInfo.shipper.colonia }} -
            {{ search.locationsInfo.shipper.cp }}
          </td>
        </tr>
        <tr>
          <td class="p-2">A:</td>
          <td class="p-2">
            {{ search.locationsInfo.recipient.colonia }} -
            {{ search.locationsInfo.recipient.cp }}
          </td>
        </tr>
      </table>

      <div class="px-3 py-3">
        <table class="w-100">
          <tr>
            <td>Peso Total</td>
            <td>{{ search.quote.mass }} Kg</td>
          </tr>
          <tr style="border-bottom: 1px solid #ccc">
            <td class="pb-1">Precio Neto</td>
            <td class="pb-1">{{ search.quote.netPrice }}</td>
          </tr>
          <tr>
            <td class="pt-1">Monto Neto total</td>
            <td class="pt-1">{{ search.quote.netPrice }}</td>
          </tr>
          <tr>
            <td>Monto del IVA</td>
            <td>{{ search.quote.iva }}</td>
          </tr>
          <tr>
            <th>Total</th>
            <th>{{ search.quote.price }}</th>
          </tr>
        </table>
      </div>
    </div>
</template>

