import axios from "axios";
import authHeader from "../auth-header";
import store from "@/store";

const backend = (uri = '') => `${process.env.VUE_APP_API_BASE_URL}/dashboard${uri}`;

const interceptor = (err) => {
    if (err.response.status == 403) {
        return store.dispatch('auth/logout');
    }

    return err.response;
};

export default {
    async getLocationsByCp(cp) {
        try {
            const response = await axios.get(
                backend(`/search/location/${cp}`),
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    async getMostWanted() {
        try {
            const response = await axios.get(
                backend(`/search/location/most-wanted`),
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    async getQuotes(search, toWeightQuote) {
        const searchFromDashboard = 'dbr';

        const payload = {
            'type'        : search.package.type,
            'mass'        : toWeightQuote,
            'high'        : search.package.high,
            'width'       : search.package.width,
            'long'        : search.package.long,
            'origin'      : searchFromDashboard,
            'shipper'     : String(search.locations.shipper.id),
            'recipient'   : String(search.locations.recipient.id),
            'willBeStored': search.locations.willBeStored,
        };

        try {
            const response = await axios.post(
                backend(`/search/quotes`),
                payload,
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            interceptor(error)
        }
    },

    async byuNow({shipper, recipient, payment}) {

        const quoteId = store.getters["dashboard/quote"];
        const searchId = store.getters["dashboard/search"];

        const payload = {
            quoteId,
            searchId,

            'shipmentDescription'  : shipper.value.shipmentDescription,
            'shipmentDeclaredValue': shipper.value.shipmentDeclaredValue == 0 ? null : shipper.value.shipmentDeclaredValue,

            'shipperName'         : shipper.value.shipperName,
            'shipperPhone'        : shipper.value.shipperPhone,
            'shipperAddress'      : shipper.value.shipperAddress,
            'shipperAddressNumber': shipper.value.shipperAddressNumber,
            'shipperReference'    : shipper.value.shipperReference,
            'shipperCompany'      : shipper.value.shipperCompany,

            'recipientName'         : recipient.value.recipientName,
            'recipientPhone'        : recipient.value.recipientPhone,
            'recipientAddress'      : recipient.value.recipientAddress,
            'recipientAddressNumber': recipient.value.recipientAddressNumber,
            'recipientReference'    : recipient.value.recipientReference,
            'recipientCompany'      : recipient.value.recipientCompany,

            'paymentPaymentType': payment.value.paymentPaymentType,
            'paymentEmail'      : payment.value.paymentEmail,
            'paymentName'       : payment.value.paymentName,
            'deviceSessionId'   : payment.value.deviceSessionId,
            'token_id'          : payment.value.token_id
        };

        try {
            const response = await axios.post(
                backend ('/search/buyNow'),
                payload,
                {headers: authHeader()}
            );

            return await response.data;
        } catch (error) {
            return interceptor(error);
        }
    }
}