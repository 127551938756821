<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref } from "vue";
import SoftField from "@/components/SoftField.vue";
import { Form } from "vee-validate";
import { useUpdateCompanySchema } from '@/services/Http/Validations/Admin/'
import { createCompany, allUsers } from "../../services/Admin";
import { company as avatar } from "../../services/UI/avatars";
import showSwal from "/src/mixins/showSwal.js";
import { useRouter } from 'vue-router';

const isLoading = ref(false);
const usersList = ref([]);
const company = ref({admin:null});
const router = useRouter()

const schema = useUpdateCompanySchema()

onMounted(async () => {
  const {value:{users}} = await allUsers({})
  usersList.value = users
});

const store = async () => {

  isLoading.value = true
  if (company.value.admin == null) {
    isLoading.value = false;
    return alert("Seleccione un Administrador para esta Empresa.")
  }

  const { data, error } = await createCompany(company.value);
  isLoading.value = false;

  if (!data.value) {

    return showSwal.methods.showSwal({
      type: "error",
      message: error.value.message,
      width: "auto",
      timer: 5000
    });
  }

  showSwal.methods.showSwal({
    type: "success",
    message: 'Éxito',
    width: "auto"
  });

  router.go()
}


</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="row mb-5">

        <!-- Content -->
        <div class="col">

          <div id="createCompany" class="card mt-4">
            <div class="card-header d-flex" v-if="company.name">
                <img
                    :src="avatar(company.name)"
                    alt="bruce"
                    class="mx-3"
                  />
                <h5 class="my-auto">{{ company.name }}</h5>
            </div>
            <Form @submit="store" :validation-schema="schema" role="form" class="text-start">
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Nombre</label>
                    <div class="form-group">
                      <soft-field
                        id="name"
                        name="name"
                        v-model.capitalize="company.name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Dirección</label>
                    <div class="form-group">
                      <soft-field
                        id="address"
                        name="address"
                        v-model="company.address"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <label class="form-label mt-2">Teléfono</label>
                    <div class="form-group">
                      <soft-field
                        id="phone"
                        name="phone"
                        v-model="company.phone"
                        maxlength="10"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label mt-2">Asignar administrador</label>
                    <div class="form-group">
                      <select class="form-select" v-model="company.admin">
                        <option :value="null" disabled> Seleccione uno </option>
                        <option v-for="u in usersList"
                          :key="u.id"
                          :value="u.id"
                        >{{ u.email }} - {{ u.name }} </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn bg-gradient-dark btn-sm mb-3 d-flex float-end"
                  :disabled="isLoading"
                >
                  <div
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm mx-1"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <span>Guardar</span>
                </button>
              </div>
          </Form>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css">


</style>