import axios from "axios";
import { useFetch } from "../Http";
import authHeader from "../auth-header";

const backend = (uri = '') => `${process.env.VUE_APP_API_BASE_URL}/b2b/billing${uri}`;
const query = (object) => '?' + new URLSearchParams(object).toString();

const getHistory = async () => {

    const {data, error} = await useFetch(backend(`/history`));

    return {data, error};
}

const getDetails = async (company, filter) => {

    const {data, error} = await useFetch(backend(`/history/${company}/details${query(filter)}`));
    return {data, error};
}

const payNow = async (company, filter) => {

    const {data, error} = await useFetch(
        backend(`/payments/${company}${query(filter)}`), 'post'
    );
    return {data, error};
}

const getPayments = async (company) => {
    const {data} = await useFetch( backend(`/payments/${company}`) );

    return data.value;
}

const downloadBill = async (company, bill) => {

    const filename = `EnviOy_PAGO_${bill}_A_${company}.pdf`;

    const response = await axios.get(
        backend(`/payments/${company}/bill/${bill}`),
        {
            headers: authHeader(),
            responseType: 'blob'
        }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export {
    getHistory, getDetails, payNow,
    getPayments, downloadBill
}
